import dayjs from "dayjs";
import getGuestString from "./getGuestString";

const getBookURL = (adults, startDate, endDate, rooms, locale) => {
  return `https://be.synxis.com/?adult=${getGuestString(
    adults || 1,
    rooms || 1
  )}&arrive=${dayjs(startDate).format("YYYY-MM-DD")}&depart=${dayjs(
    endDate
  ).format(
    "YYYY-MM-DD"
  )}&chain=24447&child=0&config=system%20default&currency=EUR&rooms=${rooms ||
    1}&hotel=30967&level=hotel&linking=30&locale=${locale}&rooms=1&src=30`;
};

export default getBookURL;
