export default function reducer(state, action) {
  // console.log("ModalReducer", state, action);

  switch (action.type) {
    case "set_showModal":
      return {
        ...state,
        ...action.payload,
      };
    default:
      console.log("ModalReducer - action unknown", action);
      return state;
  }
}
