import React from "react";

import SubMenuHotel from "./SubMenuHotelInner";
import SubMenuGastronomia from "./SubMenuGastronomiaInner";
import SubMenuExperiencias from "./SubMenuExperienciasInner";
import SubMenuSpa from "./SubMenuSpaInner";
import SubMenuConocenos from "./SubMenuConocenosInner";
import SubMenuBodega from "./SubMenuBodegaInner";
import SubMenuCorporate from "./SubMenuCorporateInner";

import { getTranslation } from "./methods/getTranslation";

import "./sub-menu-inner.scss";

const SubMenuInner = ({ setOpen, setSection, section, menu, t, i18n }) => {
  // console.log('SubMenuInner', menu)

  const getType = () => {
    switch (section) {
      case "gastronomia":
        return SubMenuGastronomia;
      case "hotel":
        return SubMenuHotel;
      case "experienciasUnicas":
        return SubMenuExperiencias;
      case "spa":
        return SubMenuSpa;
      case "sostenibilidad":
        return SubMenuConocenos;
      case "contacta":
        return SubMenuCorporate;
      case "bodega":
        return SubMenuBodega;
      default:
        return null;
    }
  };

  return (
    <div className={`sub-menu-inner${section ? " open" : ""}`}>
      <div className="row">
        <a
          onClick={() => {
            setSection(null);
            setOpen(false);
          }}
        >
          {t("cerrar")}
        </a>
        <a onClick={() => setSection(null)}>
          {getTranslation(
            menu?.menuPrincipal?.find((itm) => itm.type == section),
            "title",
            { code: i18n.language?.toUpperCase() }
          )}
        </a>
      </div>
      {section &&
        React.createElement(getType(section), {
          setOpen,
          menu,
          t,
          i18n,
        })}
    </div>
  );
};

export default SubMenuInner;
