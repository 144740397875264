import { useEffect, useState } from "react";
import Link from "next/link";
import dayjs from "dayjs";

import ABLink from "ab-link";
import Text from "ab-text";
import { useCartContext } from "ab-context-cart";
import { useUserContext } from "ab-context-user";

import SubMenuInner from "./SubMenuInner";
import { getTranslation } from "./methods/getTranslation";
import { BookModal } from "ab-reservar";
import getMenuTranslation from "./methods/getMenuTranslation";
import cleanLink from "./methods/cleanLink";
import { gtmSendBotonesHeader } from "ab-gtm";

import "./mobile-menu.scss";

const MobileMenu = ({ menu, translations, t, i18n }) => {
  const [cartState, cartDispatch] = useCartContext();
  const [showBookModal, setShowBookModal] = useState(false);

  const { contents } = cartState;
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState(null);
  const [state, dispatch] = useUserContext();
  const { id, authToken } = state;

  const { setLanguage } = dispatch;

  useEffect(() => {
    if (section) {
      setSection(null);
    }
  }, [open]);

  const transEN = getMenuTranslation(translations, "EN");
  const transES = getMenuTranslation(translations, "ES");
  const transDE = getMenuTranslation(translations, "DE");
  // console.log('MobileMenu', menu)

  return (
    <>
      <BookModal
        setShowModal={setShowBookModal}
        showModal={showBookModal}
        t={t}
        i18n={i18n}
      />
      <div className={`menu-movil${open ? " open" : ""}`}>
        <button onClick={() => setOpen(!open)}>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z"
              fill="#F9F6EF"
            />
          </svg>
        </button>

        <a href={t("/")}>
          <img src="/images/menu-logo-mobile.png" alt="Abadia Retuerta" />
        </a>

        <div>
          <a onClick={() => setShowBookModal(true)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.06177 6.27029H13.9444"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.9615 8.8738H10.9677"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.00322 8.8738H8.0094"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.03862 8.8738H5.0448"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.9615 11.4656H10.9677"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.00322 11.4656H8.0094"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.03862 11.4656H5.0448"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.6958 1.33398V3.52784"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.31027 1.33398V3.52784"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8255 2.38672H5.18064C3.22285 2.38672 2 3.47734 2 5.48207V11.5152C2 13.5514 3.22285 14.6673 5.18064 14.6673H10.8193C12.7833 14.6673 14 13.5703 14 11.5656V5.48207C14.0062 3.47734 12.7895 2.38672 10.8255 2.38672Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Hotel
          </a>

          <Link prefetch={false} href="/cesta">
            <a>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.0091 14.3348H5.44386C3.39962 14.3348 1.83135 13.5965 2.27681 10.6247L2.7955 6.59722C3.0701 5.11438 4.01595 4.54688 4.84585 4.54688H11.6315C12.4736 4.54688 13.3645 5.1571 13.6818 6.59722L14.2005 10.6247C14.5789 13.2608 13.0533 14.3348 11.0091 14.3348Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.1008 4.3998C11.1008 2.80909 9.81129 1.51956 8.22057 1.51956V1.51956C7.45457 1.51631 6.71883 1.81833 6.17604 2.35883C5.63325 2.89934 5.32812 3.63379 5.32813 4.3998H5.32812"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {contents?.itemCount ? contents?.itemCount : null} {t("cart")}
            </a>
          </Link>
        </div>
      </div>
      <div className={`mobile-menu-inner${open ? " open" : ""}`}>
        <nav>
          {menu?.menuPrincipal?.map((itm, index) => (
            <div key={`menu-principal-${index}`}>
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`lower-menu-${index}`}
              >
                <a onClick={() => setOpen(false)}>
                  {itm.type === 'contacta' ? 'Corporate' : getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>

              <a
                onClick={() => {
                  setSection(itm.type);
                }}
              >
                <img src="/images/right-chevron.svg" alt="Contacta" />
              </a>

            </div>
          ))}
          <a
            href={
              i18n.language == "en"
                ? "/en/contact"
                : i18n.language == "de"
                  ? "/de/kontact"
                  : "/contacta"
            }
            onClick={() => {
              gtmSendBotonesHeader("home", "contacta");
            }}
          >

            {menu?.menuSuperior?.contactar[`text${i18n.language}`] || "Contactar"}
          </a>

        </nav>

        <div className="row">
          <ABLink
            text={menu?.menuSuperior?.reserve[`texto${i18n.language}`]}
            href={`https://be.synxis.com/signin?adult=1&arrive=${dayjs().format(
              "YYYY-MM-DD"
            )}&chain=24447&child=0&config=system%20default&currency=EUR&depart=${dayjs()
              .add(1, "d")
              .format(
                "YYYY-MM-DD"
              )}&hotel=30967&level=hotel&linking=30&locale=${i18n.language == "es"
                ? "es-ES"
                : i18n.language == "de"
                  ? "de-DE"
                  : "en-US"
              }&rooms=1&src=30`}
            target="_blank"
            callback={() => setTimeout(() => setOpen(false), 300)}
          />
          <ABLink
            text={authToken ? t("mi-cuenta") : "Log in"}
            href={t("/mi-cuenta")}
            callback={() => setTimeout(() => setOpen(false), 300)}
          />
        </div>
        <div className="row" style={{ justifyContent: "space-between" }}>
          {/* <ABLink
            text={i18n.language == "es" ? "RSE" : "CSR"}
            href={t("/responsabilidad-social-empresarial")}
            callback={() => setTimeout(() => setOpen(false), 300)}
          /> */}
          <ABLink
            text={menu?.menuSuperior?.tour[`texto${i18n.language}`]}
            href={
              i18n.language == "en"
                ? menu?.menuSuperior?.tour?.linken ||
                "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
                : i18n.language == "de"
                  ? menu?.menuSuperior?.tour?.linkde ||
                  "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
                  : menu?.menuSuperior?.tour?.link
            }
            target="_blank"
            callback={() => setTimeout(() => setOpen(false), 300)}
          />
          <ABLink
            text={t("noticias")}
            href={t("/blog")}
            callback={() => setTimeout(() => setOpen(false), 300)}
          />
        </div>

        <div className={`language${open ? " open" : ""}`}>
          <Text element="span" className="title">
            {t("language")}
          </Text>
          {i18n.language == "es" || !transES?.link ? (
            <a
              className={i18n.language == "es" ? "selected" : undefined}
              onClick={() => {
                setLanguage("EN");
              }}
            >
              ES
            </a>
          ) : (
            <Link
              prefetch={false}
              as={
                transES?.link
                  ? `/${transES?.blog || ""}${cleanLink(transES?.link)}`
                  : ""
              }
              href={
                transES?.link
                  ? `/${transES?.blog || ""}${cleanLink(transES?.link)}`
                  : ""
              }
            >
              <a
                className={i18n.language == "es" ? "selected" : undefined}
                onClick={() => {
                  setLanguage("ES");
                }}
              >
                ES
              </a>
            </Link>
          )}
          |
          {i18n.language == "en" || !transEN?.link ? (
            <a
              className={i18n.language == "en" ? "selected" : undefined}
              onClick={() => {
                setLanguage("EN");
              }}
            >
              EN
            </a>
          ) : (
            <Link
              prefetch={false}
              locale={"en"}
              href={
                transEN?.blog
                  ? transEN?.link
                    ? `/${transEN?.blog}${cleanLink(transEN?.link, true)}`
                    : ""
                  : transEN?.link
                    ? `/${cleanLink(transEN?.link)}`
                    : ""
              }
            >
              <a
                className={i18n.language == "en" ? "selected" : undefined}
                onClick={() => {
                  setLanguage("EN");
                }}
              >
                EN
              </a>
            </Link>
          )}
          |
          {i18n.language == "de" || !transDE?.link ? (
            <a
              className={i18n.language == "de" ? "selected" : undefined}
              onClick={() => {
                setLanguage("DE");
              }}
            >
              DE
            </a>
          ) : (
            <Link
              prefetch={false}
              locale={"de"}
              href={
                transDE?.blog
                  ? transDE?.link
                    ? `/${transDE?.blog}${cleanLink(transDE?.link, true)}`
                    : ""
                  : transDE?.link
                    ? `/${cleanLink(transDE?.link)}`
                    : ""
              }
            >
              <a
                className={i18n.language == "de" ? "selected" : undefined}
                onClick={() => {
                  setLanguage("DE");
                }}
              >
                DE
              </a>
            </Link>
          )}
        </div>
      </div>
      <SubMenuInner
        menu={menu}
        setOpen={setOpen}
        setSection={setSection}
        section={section}
        t={t}
        i18n={i18n}
      />
    </>
  );
};

export default MobileMenu;
