import fetchApi from "ab-fetch-api";

import { PAGE_WITH_TRANSLATION } from "ab-api-fragments";

export async function getHeaderFooter() {
  const data = await fetchApi(
    `
    {
      headerFooter {
        footer {
          backgroundImage {
            mediaItemUrl
          }
          logo {
            mediaItemUrl
          }
          logoMobile {
            mediaItemUrl
          }
          organizations {
            link
            logo {
              mediaItemUrl
            }
          }
          legal {
            link {
              ${PAGE_WITH_TRANSLATION}
            }
          }
        }
        menu {
          hotel {
            item {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
              externallink {
                target
                title
                url
              }
              descripcion
              descripcionEn
              descripcionDe
            }
          }
          bodega {
            vinos {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            categorias {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            productosDestacados {
              producto {
                ... on SimpleProduct {
                  name
                  slug
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                  productos_ingles {
                    subtituloIngles
                  }
                  productos_aleman {
                    subtituloAleman
                  }
                }
                ... on GroupProduct {
                  name
                  slug
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                  productos_ingles {
                    subtituloIngles
                  }
                  productos_aleman {
                    subtituloAleman
                  }
                }
              }
            }
          }
          gastronomia {
            restaurante {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
              descripcion
              imagen {
                mediaItemUrl
              }
            }
          }
          spa {
            links {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            experienciasWellness {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
          }
          experienciasUnicas {
            experiencias {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            destacadas {
              descripcionEn
              descripcionEs
              descripcionDe
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
          }
          sostenibilidad {
            item {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
              externallink {
                target
                title
                url
              }
            }
          }
          contacta {
            item {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
              externallink {
                target
                title
                url
              }
            }
          }
          menuSuperior {
            tour {
              textoen
              textoes
              textode
              link
              linken
              linkde
            }
            noticias {
              texten
              textes
              textode
              link {
                url
              }
            }
            reserve {
              textoen
              textoes
              textode
              link
            }
            contactar {
              textoen
              textoes
              textode
              link {
                  url
                }            
              }
            }
          }
          menuPrincipal {
            type
            link {
              ${PAGE_WITH_TRANSLATION}
            }
          }
        }
      }
    }
    
    
    
    
    
    
    `
  );
  return data;
}
