import fetchApi from "ab-fetch-api";

export default async function refreshJwtAuthToken(refreshToken) {
  const data = await fetchApi(
    `
      mutation MyQuery($refreshToken: String!) {
        refreshJwtAuthToken(input: {jwtRefreshToken: $refreshToken}) {
          authToken
        }
      }`,
    {
      variables: { refreshToken },
    }
  );

  if (data.status === 500) {
    return data;
  } else {
    return {
      status: 200,
      data: data?.refreshJwtAuthToken,
    };
  }
}
