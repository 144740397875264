import { useState, useEffect } from "react";
import { useTranslation } from 'next-i18next';
import Numeral from "numeral";
import { filter, find, last, sortBy } from "lodash-es";
import { useRouter } from "next/router";
import Loading from "ook-loading";

import Text from "ab-text";
import { useCartContext } from "ab-context-cart";
import { useUserContext } from "ab-context-user";

import "./ab-cta.scss";

const CTA = ({
  type,
  text,
  onClick,
  plus,
  price,
  className = "",
  classname = "",
  href,
  target,
  link,
  products,
  setSelectedProduct,
  selectedProduct,
  productType,
  stockError,
  setStockError,
  maxQtyError,
  setMaxQtyError,
  isExperience,
  esNuestrosVinos,
  setNumBotellas,
  callbackAddToCart,
  seleccionNavidad,
  selectAnada,
  i18n
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useCartContext();
  const { addItemToCart } = dispatch;
  const { t } = useTranslation("common", { i18n });

  const [userState, userDispatch] = useUserContext();
  const { role, authToken } = userState;

  const [hover, setHover] = useState(false);
  const [count, setCount] = useState(1);
  const [anada, _setAnada] = useState();
  const [formato, setFormato] = useState();
const setAnada = (value) => {
  _setAnada(value);
  if(selectAnada){
    selectAnada(value);
  }
}

  let anadas = [];
  let formatos = [];

  let allAnadas = [];
  let allFormatos = [];

  let priceArray = find(selectedProduct?.metaData, [
    "key",
    `_role_base_price_${role}`,
  ])?.value?.replace(/;*(s|a):\d+/g, "")?.replace(/{/g, "")?.replace(/}/g, "")?.replace(/;/g, "")?.replace("::", "")?.split('":"') || [];

  // console.log("product detail", selectedProduct, role,  priceArray, priceArray?.indexOf("discount_value"), priceArray[priceArray.indexOf("discount_value")+1]);
  if (Numeral.locales["abadia"] === undefined) {
    Numeral.register("locale", "abadia", {
      delimiters: {
        thousands: ".",
        decimal: ",",
      },
      abbreviations: {
        thousand: "k",
        million: "mm",
        billion: "b",
        trillion: "t",
      },
      ordinal: function (number) {
        var b = number % 10;
        return b === 1 || b === 3
          ? "er"
          : b === 2
            ? "do"
            : b === 7 || b === 0
              ? "mo"
              : b === 8
                ? "vo"
                : b === 9
                  ? "no"
                  : "to";
      },
      currency: {
        symbol: "€",
      },
    });
  }

  Numeral.locale("abadia");

  products?.map((itm) => {
    allAnadas.push(find(itm.attributes.nodes, ["name", "Añada"])?.options?.[0]);

    allFormatos.push(
      find(itm.attributes.nodes, ["name", "Formato"])?.options?.[0]
    );

    if (find(itm.attributes.nodes, ["name", "Añada"])?.options?.[0] == anada) {
      formatos.push(
        find(itm.attributes.nodes, ["name", "Formato"])?.options?.[0]
      );
    }
    if (
      find(itm.attributes.nodes, ["name", "Formato"])?.options?.[0] == formato
    ) {
      anadas.push(find(itm.attributes.nodes, ["name", "Añada"])?.options?.[0]);
    }
    if (
      find(itm.attributes.nodes, ["name", "Añada"])?.options?.[0] == anada &&
      find(itm.attributes.nodes, ["name", "Formato"])?.options?.[0] == formato
    ) {
      setSelectedProduct(itm);
    }
  });

  let tempOrder = sortBy(products, (itm) => itm.attributes.nodes[0].options?.[0]);
  let filtered = filter(products, itm => itm.attributes.nodes[0].options?.[0] == last(tempOrder).attributes.nodes[0].options?.[0]);
  let sortedFilter = filtered.map(itm => itm.attributes.nodes[1].options?.[0]).sort((x, y) => {
    if (parseFloat(x) > parseFloat(y)) {
      return 1;
    }
    if (parseFloat(x) < parseFloat(y)) {
      return -1;
    }

    return 0;
  });
  // console.log(
  //   "CTA sortBy",
  //   tempOrder,
  //   last(tempOrder)?.attributes.nodes[0].options.[0],
  //   filtered,
  //   sortedFilter
  // );

  let priceToShow = priceArray.length > 0 && role
    ? parseFloat(priceArray[priceArray.indexOf("discount_value") + 1])
    : selectedProduct?.price;
 
  const maxQty = parseFloat(priceArray[priceArray.indexOf("max_qty") + 1]);
  const priceFormatted = priceToShow ? parseFloat(priceToShow?.toString()?.replace('.', '')?.replace(',', '.'))?.toFixed(2) : selectedProduct?.price;

  const _price = price?.includes("-") ? price?.split(" - ") : price;

 

  if (!formato && formatos.length > 0) {
    // console.log(
    //   "CTAProduct - useEffect",
    //   formato,
    //   formatos,
    //   formatos.includes("0.75L"),
    //   products
    // );
    setFormato(
      formatos.includes("0.75L")
        ? "0.75L"
        : formatos.includes("1.5L")
          ? "1.5L"
          : "3L"
    );
  }
  const sinStock = (!selectedProduct?.stockQuantity &&
    selectedProduct?.stockStatus != "IN_STOCK") ||
    (selectedProduct?.stockQuantity < count &&
      selectedProduct?.stockStatus != "IN_STOCK")

  useEffect(() => {
    setAnada(last(tempOrder)?.attributes.nodes[0].options?.[0]);
    setFormato(sortedFilter?.[0] == "0.375L" ? sortedFilter[1] : sortedFilter[0]);
  }, [products]);

  // console.log(
  //   "CTAProduct",
  //   products,
  //   anada,
  //   formato,
  //   anadas,
  //   formatos,
  //   type,
  //   price,
  //   allAnadas,
  //   allFormatos
  // );

  return (
    <>
      {plus ? (
        <div
          className={`ab-cta-options${hover ? " hover" : ""} ${productType === "SIMPLE" ? "small" : ""
            }`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {products ? (
            <div>
              <Text
                element="span"
                className={`title ${anada && anada != "Añada" ? "show" : ""}`}
              >
                {t("anada")}
              </Text>

              <select
                value={anada}
                onChange={(e) => {
                  setAnada(e.target.value);
                  // anada && setFormato("Formato");
                  if (!filter(products, itm => itm.attributes.nodes[0].options?.[0] == e.target.value).map(itm => itm.attributes.nodes[1].options?.[0]).includes(formato)) {
                    setFormato("Formato");
                  }
                }}
                className="cta"
              >
                <option value="">
                  {t("anada")}

                </option>
                {[...new Set(allAnadas)].sort().map((itm) => (
                  <option value={itm} key={itm}>
                    {itm}
                  </option>
                ))}
              </select>
            </div>
          ) : null}

          {products ? (
            <div>
              <Text
                element="span"
                className={`title ${formato && formato !== "Formato" ? "show" : ""
                  }`}
              >
                {t("formato")}

              </Text>

              <select
                value={formato}
                onChange={(e) => {
                  setFormato(e.target.value);
                  // formato && setAnada("Añada");
                  if (!filter(products, itm => itm.attributes.nodes[1].options?.[0] == e.target.value).map(itm => itm.attributes.nodes[0].options?.[0]).includes(anada)) {
                    setAnada("Añada");
                  }
                }}
              >
                <option value="">
                  {t("formato")}
                </option>
                {[...new Set(allFormatos)]
                  .sort((x, y) => {
                    if (parseFloat(x) > parseFloat(y)) {
                      return 1;
                    }
                    if (parseFloat(x) < parseFloat(y)) {
                      return -1;
                    }

                    return 0;
                  })
                  .map((itm) => (
                    <option
                      value={itm}
                      key={itm}
                      disabled={!formatos.includes(itm)}
                    >
                      {itm}
                    </option>
                  ))}
              </select>
            </div>
          ) : null}
          <div className="row">
            <button
              disabled={!anada && !formato && type != "anadir"}
              onClick={() => {
                if (count > 1) {
                  setCount(count - 1);
                  if (esNuestrosVinos) {
                    setNumBotellas(count - 1);
                  }
                  if (
                    stockError &&
                    count < selectedProduct?.stockQuantity &&
                    setStockError
                  ) {
                    setStockError(false);
                  }
                  if (
                    maxQtyError &&
                    count < selectedProduct?.stockQuantity &&
                    setMaxQtyError
                  ) {
                    setMaxQtyError(false);
                  }
                } else {
                  setCount(1);
                }
              }}
            >
              -
            </button>
            <span>{`${count} ${isExperience ? t('UND.') : t("botellas")
              }`}</span>
            <button
              disabled={!anada && !formato && type != "anadir"}
              onClick={() => {
                if(maxQty && maxQty > 0 && count == maxQty){
                  setMaxQtyError && setMaxQtyError(true);
                  setTimeout(() => {
                    setMaxQtyError && setMaxQtyError(false);
                  }, 7000)
                } else if (
                  count < selectedProduct?.stockQuantity ||
                  !selectedProduct?.manageStock  
                ) {
                  setCount(count + 1);
                  if (esNuestrosVinos) {
                    setNumBotellas(count + 1);
                  }
                } else {
                  setStockError && setStockError(true);
                }
              }}
            >
              +
            </button>
          </div>
          <span>
            {anada && formato
              ? Numeral(Numeral(priceToShow).value() * count).format(
                "1,000.00$"
              )
              : `${t("desde")} ${price?.includes("-")
                ? Numeral(_price[0]).format("1,000.00$") +
                " - " +
                Numeral(_price[1]).format("1,000.00$")
                : Numeral(Numeral(price).value() * count).format("1,000.00$")
              }`}
          </span>
        </div>
      ) : null}
      {href || link?.url ? onClick ?
        <div
          className={`ab-cta${type ? ` ${type}` : ""} ${className ||
            classname}`}
          onClick={onClick}
        >
          {text}
        </div>
        : (
          <a
            className={`ab-cta${type ? ` ${type}` : ""} ${className ||
              classname}`}
            href={href || link?.url}
            target={target || link?.target}
          >
            {text}
          </a>
        ) : (
        <button
          className={`ab-cta${type ? ` ${type}` : ""} ${className ||
            classname} ${(products && !anada && !formato) ||
              sinStock
              ? "disabled"
              : ""
            }`}
          onClick={async () => {
            if (
              (products && !anada && !formato) ||
              sinStock ||
              stockError
            ) {
            } else {
              setLoading(true);
              if (esNuestrosVinos) {
                await callbackAddToCart(
                  {
                    ...selectedProduct,
                    price: `${parseFloat(priceFormatted)?.toFixed(2)}€`,
                  },
                  count,
                  authToken
                )
              } else {
                await addItemToCart(
                  {
                    ...selectedProduct,
                    price: `${parseFloat(priceFormatted)?.toFixed(2)}€`,
                  },
                  count,
                  authToken
                );
              }
              if(!seleccionNavidad){
                router.push(t("/cesta"));
              } else {
                setLoading(false);
              }
            }
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          disabled={loading || (products && (!anada || !formato || anada == 'Añada' || formato == 'Formato'))}
        >
          {loading ? <Loading loading mini /> : sinStock ? (i18n?.language == "en" ? 'No stock' : i18n?.language == 'de' ? 'Ausverkauft' : 'No hay stock') : text}
        </button>

      )}
    </>
  );
};

export default CTA;
