import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import NormalLink from "ab-link";

import { getTranslation } from "./methods/getTranslation";

const SubMenuSpa = ({
  activeSection,
  activeSubSection,
  setActiveSubSection,
  type,
  menu,
  isSticky,
  t,
  i18n,
}) => {
  return (
    <div
      className={`submenu spa${
        activeSection == type || activeSubSection == type ? " open" : ""
      }${isSticky ? " sticky" : ""}`}
      onMouseEnter={() => setActiveSubSection(type)}
      onMouseLeave={() => setActiveSubSection(null)}
    >
      <div>
        <div className="left">
          <span>{t("exclusivo-santuario")}</span>
          <div>
            {menu?.links.map((itm, index) => (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`sub-menu-spa-${index}`}
              >
                <a onClick={() => setActiveSubSection(null)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            ))}
          </div>
        </div>
        <div className="left">
          <span>{t("experiencias-wellness")}</span>
          <div>
            {menu?.experienciasWellness.map((itm, index) => (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`sub-menu-spa-experiences-${index}`}
              >
                <a onClick={() => setActiveSubSection(null)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            ))}

            <NormalLink
              text={t("ver-todas-experiencias")}
              href={t("/santuario-spa-valladolid/tratamientos")}
            />
          </div>
        </div>
        <div className="right">
          {menu?.destacados.map((itm, index) => (
            <Link
              prefetch={false}
              href={getTranslation(itm, "uri", {
                code: i18n.language?.toUpperCase(),
              })}
              key={`sub-menu-spa-experiences-featured${index}`}
            >
              <a onClick={() => setActiveSubSection(null)}>
                <div className="image">
                  {itm?.link?.featuredImage?.node?.mediaItemUrl && (
                    <Image
                      layout="fill"
                      src={
                        itm?.link?.featuredImage?.node?.mediaItemUrl ||
                        "/images/404.png"
                      }
                      alt={getTranslation(itm, "title", {
                        code: i18n.language?.toUpperCase(),
                      })}
                    />
                  )}
                </div>
                <span>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </span>

                <p>{itm?.[`descripcion${i18n.language?.toUpperCase()}`]}</p>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubMenuSpa;
