import Head from "next/head";
import { useRouter } from "next/router";
function removeLeadingSlash(str) {
  if (str.startsWith('/')) {
    return str.slice(1);
  }
  return str;
}
const SEO = ({ seo, notFound, locale }) => {
  const router = useRouter();

  const robotTagContent = (seo?.noindex || notFound) ? 'noindex, nofollow' : 'index, follow'
  return (
    <Head>
      <title>
        {seo?.title ||
          "Abadía Retuerta: Hotel Bodega Restaurante & SPA en Valladolid"}
      </title>
      <meta name="description" content={seo?.description}></meta>
      {seo?.keywords ? <meta name="keywords" content={seo?.keywords} /> : null}
      <meta name="robots"
        content={`${robotTagContent}`}
      />
      <meta
        name="googlebot"
        content={`${robotTagContent}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      ></meta>
      <meta
        name="bingbot"
        content={`${robotTagContent}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      ></meta>
      <link
        rel="canonical"
        href={`https://www.abadia-retuerta.com/${locale !== "es" ? locale + router.asPath : removeLeadingSlash(router.asPath)}`}
      ></link>
      <meta
        property="og:url"
        content={`https://www.abadia-retuerta.com${router.asPath}`}
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={seo?.title} />
      <meta property="og:description" content={seo?.description} />
      <meta name="twitter:title" content={seo?.title} />
      <meta name="twitter:description" content={seo?.description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#000"></meta>
      <script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="018e4161-98cb-7d33-98c2-5e66f6a88dfc"
      ></script>
      <script type="text/javascript">
        {`
          function OptanonWrapper() { }
        `}
      </script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            // Default ad_storage to 'denied'. 
            gtag('consent', 'default', { 
              'ad_storage': 'denied', 
              'analytics_storage': 'denied', 
              'personalization_storage': 'denied', 
              'ad_user_data': 'denied', 
              'ad_personalization': 'denied', 
              'wait_for_update': 500 
            });
          `,
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
          `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer.push({
              'event': 'clic_sigues_interesado',
              'alojamiento': '{{alojamiento}}', // Tipo de habitación
              'fecha': '{{fecha}}', // Fechas de entrada y salida del usuario
              'huespedes': '{{Numero}}', // número de huespedes que se van a hospedar
              'lead': '{{tipo_de_lead}}', // Si el usuario es socio o no
              'userid': '{{id}}' // Este es el id del usuario
            });
          `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer.push({
              'event': 'No_clic_sigues_interesado',
              'lead': '{{tipo_de_lead}}', // Si el usuario es socio o no
              'userid': '{{id}}' // Este es el id del usuario
            });
          `,
        }}
      />
    </Head>
  );
};

export default SEO;
