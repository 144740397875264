import fetchApi from "ab-fetch-api";
import { CART_INFO } from "ab-api-fragments";

export default async function applyCoupon(codeRef) {
  const code = codeRef.toLowerCase();

  const data = await fetchApi(
    `
      mutation applyCoupon($code: String!) {
        applyCoupon(input: {code: $code}) {
          ${CART_INFO}
        }
      }
    `,
    {
      variables: { code },
    }
  );

  if (data?.[0]) {
    return data;
  } else {
    return data?.applyCoupon;
  }
}
