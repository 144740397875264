import fetchApi from "ab-fetch-api";

export async function getTestimonios(first, after) {
  const data = await fetchApi(
    `
    query getTestimonios($first: Int, $after: String) {
        testimonios(first: $first, after: $after) {
          nodes {
            title
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            testimonios {
              cargo
            }
            content
          }
        }
      }
  `,
    {
      variables: {
        first,
        after,
      },
      errorPolicy: "ignore",
    }
  );
  return data;
}
