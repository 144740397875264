import { getCart } from "./getCart";
import { getCategories } from "./getCategories";
import { getCategory } from "./getCategory";
import { getCustomerOrder } from "./getCustomerOrder";
import { getCustomerOrders } from "./getCustomerOrders";
import { getDatosPersonales } from "./getDatosPersonales";
import { getHeaderFooter } from "./getHeaderFooter";
import { getModCross } from "./getModCross";
import { getPage } from "./getPage";
import { getPageStaticPaths } from "./getPageStaticPaths";
import { getPost } from "./getPost";
import { getPosts } from "./getPosts";
import { getProduct } from "./getProduct";
import { getProductsByCategory } from "./getProductsByCategory";
import { getSubCategories } from "./getSubCategories";
import { getTestimonios } from "./getTestimonios";

export {
  getCart,
  getCategories,
  getCategory,
  getCustomerOrder,
  getCustomerOrders,
  getDatosPersonales,
  getHeaderFooter,
  getModCross,
  getPage,
  getPageStaticPaths,
  getPost,
  getPosts,
  getProduct,
  getProductsByCategory,
  getSubCategories,
  getTestimonios,
};
