import Numeral from "numeral";
import dayjs from "dayjs";
import "dayjs/locale/es";
// import { appWithTranslation } from 'next-i18next';
// import nextI18NextConfig from '../next-i18next.config.js';

import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import Menu from "ab-menu";
import Footer from "ab-footer";
import SEO from "ab-seo";
import { AppProvider } from "ab-context-app";
import { ModalProvider } from "ab-context-modal";
import client from "../lib/apolloClient";
import { UserProvider } from "ab-context-user";
import { CartProvider } from "ab-context-cart";
import { CheckoutProvider } from "ab-context-checkout";
import { EboxProvider } from "ab-context-ebox";
import Cookies from "ab-cookies";
import { appWithTranslation, useTranslation } from "next-i18next";
import { PopUp } from "ab-context-modal";

import "../css/app.modules.scss";
// import "../utils/i18n";
const showCierreHotelPages = [
  '/', '/home', '/start',
  '/hotel-spa-valladolid-ribera-del-duero',
  '/en-hotel-spa-valladolid-ribera-del-duero',
  '/de-hotel-spa-valladolid-ribera-del-duero',
  '/restaurantes-valladolid',
  '/restaurants-valladolid',
  '/valladolid-restaurants',
  '/santuario-spa-valladolid',
  '/en-santuario-spa-valladolid',
  '/santuario-wellness-spa'

]
const MyApp = ({ Component, pageProps, ...rest }) => {

  // const showingCierrePopup = showCierreHotelPages.includes(router.asPath);

  if (Numeral.locales["abadia"] === undefined) {
    Numeral.register("locale", "abadia", {
      delimiters: {
        thousands: ".",
        decimal: ",",
      },
      abbreviations: {
        thousand: "k",
        million: "mm",
        billion: "b",
        trillion: "t",
      },
      ordinal: function (number) {
        var b = number % 10;
        return b === 1 || b === 3
          ? "er"
          : b === 2
            ? "do"
            : b === 7 || b === 0
              ? "mo"
              : b === 8
                ? "vo"
                : b === 9
                  ? "no"
                  : "to";
      },
      currency: {
        symbol: "€",
      },
    });
  }

  Numeral.locale("abadia");
  dayjs.locale("es");


  const { t, i18n } = useTranslation();

  return (
    <UserProvider>
      <CartProvider>
        <CheckoutProvider>
          <EboxProvider>
            <AppProvider>
              <ModalProvider>
                <Head>
                  <meta
                    httpEquiv="Content-type"
                    content="text/html; charset=UTF-8"
                  />
                  <link rel="stylesheet" href="/css/reset.css"></link>
                  <script
                    dangerouslySetInnerHTML={{
                      __html: `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window,document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                   fbq('init', '467436628022477'); fbq('track', 'PageView');    `,
                    }}
                  />
                  <meta
                    name="google-site-verification"
                    content="mRDBXCPrG23FMEMmTukCoJuIdvjL50Pa3wWLQcSdFwA"
                  />

                  {/* <script
                    src="https://cdn.popupsmart.com/bundle.js"
                    data-id="80545"
                    async
                    defer
                  /> */}
                </Head>
                <ApolloProvider client={client}>
                  <SEO seo={pageProps.seo} notFound={pageProps.notFound} locale={pageProps?.language?.code.toLowerCase() ?? 'es'} />
                  <Menu
                    menu={pageProps.menu}
                    translations={pageProps.translations}
                    language={pageProps.language}
                    t={t}
                    i18n={i18n}
                    dark={
                      !pageProps.content?.find(
                        (itm) =>
                          itm.fieldGroupName ==
                          "Page_Contenidoflexible_Modulos_Hero"
                      ) &&
                      !pageProps.content?.find(
                        (itm) =>
                          itm.fieldGroupName ==
                          "Page_Contenidoflexible_Modulos_BlogPost"
                      )
                    }
                  />
                  <Component {...pageProps} />
                  <Footer
                    menu={pageProps.menu}
                    data={pageProps.footer}
                    language={pageProps.language}
                    t={t}
                    i18n={i18n}
                  />
                </ApolloProvider>
                <noscript>
                  <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM}`}
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                  ></iframe>
                </noscript>
                <noscript>
                  <img
                    height="1"
                    width="1"
                    style={{ display: "none" }}
                    src="https://www.facebook.com/tr?id={your-pixel-id-goes-here}&ev=PageView&noscript=1"
                  />
                </noscript>

                {/* <Cookies i18n={i18n} t={t} /> */}

                {/* {showingCierrePopup && <PopUp cookieId="cierre-hotel" content={[
                 {image:"/images/popup-cierre-hotel.png", title:t("preparing") ,body:t("cierreHotel")}
               ]} */}
                <PopUp cookieId="premios" content={[
                  { image: "/images/Habitación Master Suite-min.png", title: t('popup-premios.3.title'), body: t('popup-premios.3.body'), size: { width: 300, height: 200 } }
                  // { image: "/images/logo_3_llaves.png", title: t('popup-premios.2.title'), body: t('popup-premios.2.body'), size: { width: 100, height: 100 } },
                ]}
                  config={{ sliderInterval: 2500 }}
                />
              </ModalProvider>
            </AppProvider>
          </EboxProvider>
        </CheckoutProvider>
      </CartProvider>
    </UserProvider>
  );
};

export default appWithTranslation(MyApp);
