import Cookies from "js-cookie";

import { defaultState } from "./provider";

export const encodeToken = (token) => {
  if (!token) {
    return null;
  }

  return btoa(token);
};

export default function reducer(state, action) {
  // console.log("UserReducer", state, action);

  switch (action.type) {
    case "logout":
      if (action.payload == "hard") {
        localStorage.removeItem("woo-session");
      }

      localStorage.removeItem(process.env.USER_ID);
      localStorage.removeItem(process.env.AUTH_TOKEN_NAME);
      sessionStorage.removeItem(process.env.USER_ID);
      sessionStorage.removeItem(process.env.AUTH_TOKEN_NAME);
      Cookies.remove(process.env.REFRESH_TOKEN_NAME);

      return {
        ...defaultState,
      };
    case "set_user":
      // console.log("set_user action", action.payload);
      let user = action?.payload?.customer;
      return {
        ...state,
        ...user,
      };
    case "register_tokens":
      // console.log("register_tokens", action.payload);
      if (action.payload.rememberMe) {
        localStorage.setItem(
          process.env.USER_ID,
          encodeToken(action.payload.customerId)
        );
        localStorage.setItem(
          process.env.AUTH_TOKEN_NAME,
          encodeToken(action.payload.authToken)
        );
      } else {
        sessionStorage.setItem(
          process.env.USER_ID,
          encodeToken(action.payload.customerId)
        );
        sessionStorage.setItem(
          process.env.AUTH_TOKEN_NAME,
          encodeToken(action.payload.authToken)
        );
      }

      Cookies.set(
        process.env.REFRESH_TOKEN_NAME,
        encodeToken(action.payload.refreshToken),
        {
          HttpOnly: false,
          expires: 365,
        }
      );
      return {
        ...state,
        ...action.payload,
      };


    case "set_navigatorLanguage":
      localStorage.setItem(
        process.env.DEFAULT_LANG || 'ab-lang',
        action.payload.userLanguage);
      return {
        ...state,
        ...action.payload,
      };
    case "register_new_token":
      localStorage.setItem(
        process.env.AUTH_TOKEN_NAME,
        encodeToken(action.payload)
      );
      sessionStorage.setItem(
        process.env.AUTH_TOKEN_NAME,
        encodeToken(action.payload)
      );
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      console.log("UserReducer - error - action unknown", action);
  }
}
