/**
 * Returns index of the product if it exists.
 */
const isProductInCart = (existingProductsInCart, productId, variationId) => {
  const returnItemThatExits = (item, index) => {
    if (
      productId === item.databaseId &&
      (variationId === undefined || variationId === item.variationId)
    ) {
      return item;
    }
  };

  // This new array will only contain the product which is matched.
  const newArray = existingProductsInCart.filter(returnItemThatExits);

  return existingProductsInCart.indexOf(newArray[0]);
};

export default isProductInCart;
