import Link from "next/link";
import Image from "next/image";

import Text from "ab-text";
import { getTranslation } from "ab-functions";

import "./footer.scss";

const Footer = ({ menu, data, t, i18n }) => {
  // console.log("footer", data);
  const ioslink = "http://maps.apple.com/?q=41.61669674632657,-4.41105580542196";
  const googleMapsUrl = "https://goo.gl/maps/AQtzW4MJP5i8S4nH9";
  const isIOS = () => {
    if (typeof window !== "undefined") return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };
  const mapLink = isIOS()
    ? ioslink
    : googleMapsUrl;

  return (
    <footer
      style={
        data?.backgroundImage?.mediaItemUrl
          ? {
            backgroundImage: `url(${data?.backgroundImage?.mediaItemUrl})`,
          }
          : undefined
      }
    >
      <img className="footer-logo" src={data?.logo?.mediaItemUrl} alt="logo" />
      <a href={t("/")} className="footer-logo-mobile">
        <Image
          width={188}
          height={92}
          src={data?.logoMobile?.mediaItemUrl || "/images/404.png"}
          alt="Abadia Retuerta"
        />
      </a>

      <nav>
        {menu?.menuPrincipal?.map((itm, index) => (
          <Link
            prefetch={false}
            href={getTranslation(itm, "uri", {
              code: i18n?.language?.toUpperCase(),
            })}
            key={`lower-menu-${index}`}
          >
            <a>
              {getTranslation(itm, "title", {
                code: i18n?.language?.toUpperCase(),
              })}
            </a>
          </Link>
        ))}
      </nav>

      <div className="social">
        <a
          href={data?.instagram || "https://www.instagram.com/abadiaretuerta_/"}
          target="_blank"
        >
          <Image
            width={22}
            height={22}
            src="/images/instagram.png"
            loading="lazy"
            alt="instagram"
          />
        </a>
        <a
          href={
            data?.facebook ||
            "https://www.facebook.com/AbadiaRetuertaLeDomaine/"
          }
          target="_blank"
        >
          <Image
            width={22}
            height={22}
            src="/images/facebook.png"
            loading="lazy"
            alt="facebook"
          />
        </a>
        <a
          href={data?.twitter || "https://twitter.com/arledomaine"}
          target="_blank"
        >
          <Image
            width={22}
            height={22}
            src="/images/twitter.png"
            loading="lazy"
            alt="twitter"
          />
        </a>
        <a
          href={
            data?.youtube || "https://www.youtube.com/user/abadiaretuertabodega"
          }
          target="_blank"
        >
          <Image
            width={22}
            height={22}
            src="/images/youtube.png"
            loading="lazy"
            alt="youtube"
          />
        </a>
        <a
          href={
            data?.linkedin ||
            "https://www.linkedin.com/company/abadia-retuerta/"
          }
          target="_blank"
        >
          <Image
            width={22}
            height={22}
            src="/images/linkedin.png"
            loading="lazy"
            alt="linked in"
          />
        </a>
      </div>

      <div className="logos">
        {data?.organizations?.map((itm, index) => (
          <a key={`organization-${index}`} href={itm.link} target="_blank">
            <Image
              width={index == 9 ? 100 : 173}
              height={index == 9 ? 100 : index >= 10 && index <= 13 ? 173 : 84}
              objectFit="contain"
              src={itm?.logo?.mediaItemUrl || "/images/404.png"}
              alt={`organization-${index}`}
            />
          </a>
        ))}
      </div>
      <div className="row addres">
        <a className="title" href="tel:+34 983 687 600"><Text element="span" className="title">+34 983 687 600</Text></a>
        <a
          href={mapLink}
          target={"__blank"}
          className="title"
        ><Text element="span" className="title">
            Sardón de Duero, 47340 Valladolid (España)
          </Text></a>
        <Text element="span" className="title">
          {data?.copyright || "® 2021 Abadía Retuerta LeDomaine"}
        </Text>
      </div>


      <div className="row">
        {data?.legal?.map((itm, index) => (
          <Link
            prefetch={false}
            href={getTranslation(itm, "uri", {
              code: i18n?.language?.toUpperCase(),
            })}
            key={`lower-menu-${index}`}
          >
            <a>
              <Text element="span" className="title">
                {getTranslation(itm, "title", {
                  code: i18n?.language?.toUpperCase(),
                })}
              </Text>
            </a>
          </Link>
        ))}

        {/* <Link
          prefetch={false}
          href={
            i18n?.language == "en"
              ? "https://nvs.i-sight.com/portal"
              : i18n?.language == "de"
              ? "https://nvs.i-sight.com/portal?lang=de_de"
              : "https://nvs.i-sight.com/portal?lang=es"
          }
        >
          <a>
            <Text element="span" className="title">
              {i18n?.language == "en"
                ? "Information channel"
                : i18n?.language == "de"
                ? "Informationskanal"
                : "Canal de información"}
            </Text>
          </a>
        </Link> */}
      </div>
    </footer>
  );
};

export default Footer;
