import React, { useReducer } from "react";

const defaultState = {
  step: 0,
  user: null,
  billing: null,
  shipping: null,
  privacyPolicyAccepted: false,
};

import reducer from "./reducer";

const CheckoutStateContext = React.createContext();
const CheckoutDispatchContext = React.createContext();

const CheckoutProvider = ({ children }) => {
  // const [state, dispatch] = useImmer({ ...defaultState });
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
  });

  // console.log("CheckoutProvider - state - ", state);

  return (
    <CheckoutStateContext.Provider value={state}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
};

export {
  defaultState,
  CheckoutProvider,
  CheckoutStateContext,
  CheckoutDispatchContext,
};
