import fetchApi from "ab-fetch-api";

export default async function sendPasswordResetEmail(email) {
  const data = await fetchApi(
    `
      mutation SendPasswordResetEmail($email: String!) {
        sendPasswordResetEmail(input: {username: $email}) {
          clientMutationId
        }
      }`,
    {
      variables: { email },
    }
  );
  // console.log("paswordreset", data);
  if (data?.status === 500) {
    return data.errors;
  } else {
    return data?.sendPasswordResetEmail;
  }
}
