import { useEffect } from "react";
import dayjs from "dayjs";
import Link from "next/link";

import { useCartContext } from "ab-context-cart";
import { useUserContext } from "ab-context-user";
import { gtmSendBotonesHeader } from "ab-gtm";
import { updateUser } from "ab-api-mutations";
import getMenuTranslation from "./methods/getMenuTranslation";
import cleanLink from "./methods/cleanLink";
import "./upper-menu.scss";

const UpperMenu = ({ translations, menu, t, i18n }) => {
  const [cartState, cartDispatch] = useCartContext();
  const { contents } = cartState;
  const [state, dispatch] = useUserContext();
  const { firstName, id, authToken, userLanguage } = state;
  const { setLanguage } = dispatch;

  const transEN = getMenuTranslation(translations, "EN");
  const transES = getMenuTranslation(translations, "ES");
  const transDE = getMenuTranslation(translations, "DE");
  // console.log('menu', menu)
  useEffect(() => {
    if (
      i18n.language == "en" ||
      (window.location.pathname?.includes("/en/") && userLanguage !== "EN")
    ) {
      setLanguage("EN");
    } else if (
      i18n.language == "de" ||
      (window.location.pathname?.includes("/de/") && userLanguage !== "DE")
    ) {
      setLanguage("DE");
    } else if (userLanguage !== "ES") {
      setLanguage("ES");
    }
  }, [contents]);

  // console.log(
  //   "transDE",
  //   transDE,
  //   transDE?.blog,
  //   transDE?.link,
  //   transDE?.blog
  //     ? transDE?.link
  //       ? `/${transDE?.blog}${cleanLink(transDE?.link, true)}`
  //       : ""
  //     : transDE?.link
  //       ? `/${cleanLink(transDE?.link)}`
  //       : ""
  // );

  return (
    <div className="upper">
      <div className="inner">
        <a href="/"></a>
        <div>
          {/* <a
            href={
              i18n.language == "en"
                ? "/en/environmental-social-and-corporate-governance-esg"
                : i18n.language == "de"
                ? "/de/unternehmensverantwortung-esg"
                : "/sostenibilidad-esg"
            }
            onClick={() => {
              gtmSendBotonesHeader("home", "RSE");
            }}
          >
            <img src="/images/rse.png" alt={"ESG"} />

            {"ESG"}
          </a> */}
          <a
            href={
              i18n.language == "en"
                ? menu?.tour?.linken ||
                "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
                : i18n.language == "de"
                  ? menu?.tour?.linkde ||
                  "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
                  : menu?.tour?.link
            }
            target="_blank"
            onClick={() => {
              gtmSendBotonesHeader("home", "tour virtual");
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="7" stroke="white" />
              <ellipse cx="8" cy="7.99935" rx="7" ry="2.33333" stroke="white" />
              <ellipse
                cx="7.99992"
                cy="8"
                rx="7"
                ry="2.33333"
                transform="rotate(90 7.99992 8)"
                stroke="white"
              />
            </svg>
            {menu?.tour[`texto${i18n.language}`]}
          </a>
          <a
            href={
              i18n.language == "en"
                ? "/en/en-blog"
                : i18n.language == "de"
                  ? "/de/de-blog"
                  : "/blog"
            }
            onClick={() => {
              gtmSendBotonesHeader("home", "blog");
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4268 6.63867L10.0943 9.34845C9.46468 9.84795 8.57883 9.84795 7.94921 9.34845L4.58862 6.63867"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6816 15.75C14.9627 15.7563 16.5 13.8822 16.5 11.5788V6.42751C16.5 4.12412 14.9627 2.25 12.6816 2.25H5.31835C3.03734 2.25 1.5 4.12412 1.5 6.42751V11.5788C1.5 13.8822 3.03734 15.7563 5.31835 15.75H12.6816Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {menu?.noticias[`text${i18n.language}`]}
          </a>

          <a
            href={`https://be.synxis.com/signin?adult=1&arrive=${dayjs().format(
              "YYYY-MM-DD"
            )}&chain=24447&child=0&config=system%20default&currency=EUR&depart=${dayjs()
              .add(1, "d")
              .format(
                "YYYY-MM-DD"
              )}&hotel=30967&level=hotel&linking=30&locale=${i18n.language == "es"
                ? "es-ES"
                : i18n.language == "de"
                  ? "de-DE"
                  : "en-US"
              }&rooms=1&src=30`}
            target="_blank"
            onClick={() => {
              gtmSendBotonesHeader("home", "reserve");
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.06177 6.27029H13.9444"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.9615 8.8738H10.9677"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.00322 8.8738H8.0094"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.03862 8.8738H5.0448"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.9615 11.4656H10.9677"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.00322 11.4656H8.0094"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.03862 11.4656H5.0448"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.6958 1.33398V3.52784"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.31027 1.33398V3.52784"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8255 2.38672H5.18064C3.22285 2.38672 2 3.47734 2 5.48207V11.5152C2 13.5514 3.22285 14.6673 5.18064 14.6673H10.8193C12.7833 14.6673 14 13.5703 14 11.5656V5.48207C14.0062 3.47734 12.7895 2.38672 10.8255 2.38672Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {menu?.reserve[`texto${i18n.language}`]}
          </a>

          <a
            href={
              i18n.language == "en"
                ? "/en/contact"
                : i18n.language == "de"
                  ? "/de/kontact"
                  : "/contacta"
            }
            onClick={() => {
              gtmSendBotonesHeader("home", "contacta");
            }}
          >

            {menu?.contactar[`text${i18n.language}`] || "Contactar"}
          </a>
          <Link
            prefetch={false}
            href={
              i18n.language == "en"
                ? "/en/my-account"
                : i18n.language == "de"
                  ? "/de/mein-konto"
                  : "/mi-cuenta"
            }
          >
            <a
              onClick={() => {
                gtmSendBotonesHeader("home", "log in");
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.98979 10.2305C5.41138 10.2305 3.20947 10.6203 3.20947 12.1816C3.20947 13.7428 5.39741 14.1467 7.98979 14.1467C10.5682 14.1467 12.7695 13.7562 12.7695 12.1955C12.7695 10.6349 10.5822 10.2305 7.98979 10.2305Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.98993 8.00457C9.68199 8.00457 11.0534 6.63251 11.0534 4.94045C11.0534 3.24838 9.68199 1.87695 7.98993 1.87695C6.29786 1.87695 4.9258 3.24838 4.9258 4.94045C4.92008 6.62679 6.28262 7.99886 7.96834 8.00457H7.98993Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {firstName || "Log in"}
            </a>
          </Link>
          <Link
            prefetch={false}
            href={
              i18n.language == "en"
                ? "/en/cart"
                : i18n.language == "de"
                  ? "/de/warenkorb"
                  : "/cesta"
            }
            onClick={() => {
              gtmSendBotonesHeader("home", "cesta");
            }}
          >
            <a>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={contents?.itemCount ? undefined : { marginRight: 0 }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.0091 14.3348H5.44386C3.39962 14.3348 1.83135 13.5965 2.27681 10.6247L2.7955 6.59722C3.0701 5.11438 4.01595 4.54688 4.84585 4.54688H11.6315C12.4736 4.54688 13.3645 5.1571 13.6818 6.59722L14.2005 10.6247C14.5789 13.2608 13.0533 14.3348 11.0091 14.3348Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.1008 4.3998C11.1008 2.80909 9.81129 1.51956 8.22057 1.51956V1.51956C7.45457 1.51631 6.71883 1.81833 6.17604 2.35883C5.63325 2.89934 5.32812 3.63379 5.32813 4.3998H5.32812"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {contents?.itemCount ? <span>{contents?.itemCount}</span> : null}
            </a>
          </Link>

          {/* SELECTOR IDIOMAS */}
          <div>
            {/* ESPAÑOL */}
            {i18n.language == "es" || !transES?.link ? (
              <a
                className={i18n.language == "es" ? "selected" : undefined}
                onClick={() => {
                  gtmSendBotonesHeader("home", "es");
                  updateUser(
                    {
                      id,
                      locale: "es_ES",
                    },
                    authToken
                  );
                  setLanguage("ES");
                }}
              >
                ES
              </a>
            ) : (
              <Link
                prefetch={false}
                locale={"es"}
                as={
                  transES?.link
                    ? `/${transES?.blog || ""}${cleanLink(transES?.link)}`
                    : ""
                }
                href={
                  transES?.link
                    ? `/${transES?.blog || ""}${cleanLink(transES?.link)}`
                    : ""
                }
              >
                <a
                  className={i18n.language == "es" ? "selected" : undefined}
                  onClick={() => {
                    gtmSendBotonesHeader("home", "es");
                    updateUser(
                      {
                        id,
                        locale: "es_ES",
                      },
                      authToken
                    );
                    setLanguage("ES");
                  }}
                >
                  ES
                </a>
              </Link>
            )}
            {/* INGLES */}|
            {i18n.language == "en" || !transEN?.link ? (
              <a
                className={i18n.language == "en" ? "selected" : undefined}
                onClick={() => {
                  gtmSendBotonesHeader("home", "en");
                  updateUser(
                    {
                      id,
                      locale: "en_US",
                    },
                    authToken
                  );
                  setLanguage("EN");
                }}
              >
                EN
              </a>
            ) : (
              <Link
                prefetch={false}
                locale={"en"}
                href={
                  transEN?.blog
                    ? transEN?.link
                      ? `/${transEN?.blog}${cleanLink(transEN?.link, true)}`
                      : ""
                    : transEN?.link
                      ? `/${cleanLink(transEN?.link)}`
                      : ""
                }
              >
                <a
                  className={i18n.language == "en" ? "selected" : undefined}
                  onClick={() => {
                    gtmSendBotonesHeader("home", "en");
                    updateUser(
                      {
                        id,
                        locale: "en_US",
                      },
                      authToken
                    );
                    setLanguage("EN");
                  }}
                >
                  EN
                </a>
              </Link>
            )}
            {/* ALEMAN */}|
            {i18n.language == "de" || !transDE?.link ? (
              <a
                className={i18n.language == "de" ? "selected" : undefined}
                onClick={() => {
                  gtmSendBotonesHeader("home", "de");
                  updateUser(
                    {
                      id,
                      locale: "de_DE",
                    },
                    authToken
                  );
                  setLanguage("DE");
                }}
              >
                DE
              </a>
            ) : (
              <Link
                prefetch={false}
                locale={"de"}
                href={
                  transDE?.blog
                    ? transDE?.link
                      ? `/${transDE?.blog}${cleanLink(transDE?.link, true)}`
                      : ""
                    : transDE?.link
                      ? `/${cleanLink(transDE?.link)}`
                      : ""
                }
              >
                <a
                  className={i18n.language == "de" ? "selected" : undefined}
                  onClick={() => {
                    gtmSendBotonesHeader("home", "de");
                    updateUser(
                      {
                        id,
                        locale: "de_DE",
                      },
                      authToken
                    );
                    setLanguage("DE");
                  }}
                >
                  DE
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpperMenu;
