import fetchApi from "ab-fetch-api";
import { CART_INFO } from "ab-api-fragments";

export default async function updateItemQuantities(items, token) {
  console.log("updateItemQuantities", token);
  let variables = { variables: { items } };

  let query = `
  mutation updateItemQuantities($items: [CartItemQuantityInput]) {
    updateItemQuantities(input: {items: $items }) {
      ${CART_INFO}
    }
  }
  
    `;

  const data = await fetchApi(query, variables, token);

  if (data?.[0]) {
    return data;
  } else {
    return data?.updateItemQuantities?.cart;
  }
}
