import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import UpperMenu from "./UpperMenu";
import LowerMenu from "./LowerMenu";
import MobileMenu from "./MobileMenu";
import SubMenu from "./SubMenu";

import "./menu.scss";

const Menu = ({ className, dark, menu, translations, t, i18n }) => {
  const router = useRouter();
  const [activeSection, setActiveSection] = useState(null);
  const [activeSubSection, setActiveSubSection] = useState(null);
  const [isSticky, setIsSticky] = useState(false);

  const handleSticky = () => {
    if (window.pageYOffset > 10) {
      document.getElementById("menu")?.classList.add("sticky");
      setIsSticky(true);
    } else {
      document.getElementById("menu")?.classList.remove("sticky");
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);

    if (window.pageYOffset > 10) {
      document.getElementById("menu")?.classList.add("sticky");
      setIsSticky(true);
    } else {
      document.getElementById("menu")?.classList.remove("sticky");
      setIsSticky(false);
    }

    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  // console.log("menu?.menuPrincipal", menu?.menuPrincipal);

  return (
    <menu
      className={`${className || ""} ${router.asPath == "/contacta" || router.asPath == "/en/contact" || dark
        ? "dark"
        : ""
        }`}
      id="menu"
    >
      <UpperMenu
        menu={menu?.menuSuperior}
        translations={translations}
        t={t}
        i18n={i18n}
      />

      <LowerMenu
        menu={menu}
        activeSection={activeSection}
        activeSubSection={activeSubSection}
        setActiveSection={setActiveSection}
        t={t}
        i18n={i18n}
      />

      {menu?.menuPrincipal?.map((itm, index) =>{
        return (
        <SubMenu
          key={`menu-${index}`}
          type={itm.type}
          menu={menu?.[itm.type]}
          tourLink={
            i18n.language == "en"
              ? menu?.menuSuperior?.tour?.linken ||
              "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
              : i18n.language == "de"
                ? menu?.menuSuperior?.tour?.linkde ||
                "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
                : menu?.menuSuperior?.tour?.link
          }
          tourText={
            menu?.menuSuperior?.tour[`texto${i18n.language}`] || "Tour Virtual"
          }
          page={itm.link}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          activeSubSection={activeSubSection}
          setActiveSubSection={setActiveSubSection}
          isSticky={isSticky}
          t={t}
          i18n={i18n}
        />
      )})}

      <MobileMenu menu={menu} translations={translations} t={t} i18n={i18n} />
    </menu>
  );
};

export default Menu;
