import fetchApi from "ab-fetch-api";
import { SIMPLE_PRODUCT_INFO } from "ab-api-fragments";

export default async function removeItemsFromCart(key, token) {
  let variables = {
    variables: {
      input: {
        keys: [key],
      },
    },
  };

  let query = `
  mutation removeItemsFromCart($input: RemoveItemsFromCartInput!) {
    removeItemsFromCart(input: $input) {
      cart {
        appliedCoupons {
          code
          discountAmount
          discountTax
        }
        contents {
          nodes {
            key
            product {
              node {
                attributes {
                  nodes {
                    name
                    options
                  }
                }
                databaseId
                description(format: RAW)
                image {
                  mediaItemUrl
                }
                galleryImages {
                  nodes {
                    mediaItemUrl
                  }
                }
                id
                link
                name
                sku
                ... on SimpleProduct {
                  ${SIMPLE_PRODUCT_INFO}
                }
              }
            }
            quantity
            subtotal
            subtotalTax
            tax
            total
          }
          itemCount
          productCount
        }
        total
        shippingTax
        shippingTotal
      }
    }
  }
  
    `;

  const data = await fetchApi(query, variables, token);

  if (data?.[0]) {
    return data;
  } else {
    return data?.removeItemsFromCart?.cart;
  }
}
