export const getTranslation = (itm, prop, lang) => {
  const translation =
    itm?.link?.translations.filter(
      (trans) => trans?.language?.code == lang?.code
    ) || [];
  // console.log("getTranslation", translation);
  // console.log("getTranslation - itm?.link?.translations", itm?.link?.translations);
  if (!prop) {
    return translation;
  }

  return lang?.code !== "ES"
    ? translation?.[0]?.[prop] || itm?.link?.[prop]
    : itm?.link?.[prop];
};
