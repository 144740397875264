import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { last } from "lodash-es";
import Image from "next/image";

import Text from "ab-text";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: -1,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const ImageComponent = ({
  images = [],
  imagesMobile = [],
  hideCounter = false,
}) => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images?.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <>
      <div className="gallery">
        <div className="image">
          <AnimatePresence initial={false} custom={direction}>
            {images?.length > 1 ? (
              <motion.div
                key={page}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0 },
                }}
                drag={"x"}
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
              >
                {images[imageIndex]?.srcSet ? (
                  <Image
                    src={images[imageIndex]?.srcSet || "/images/404.png"}
                    layout="fill"
                    objectFit="cover"
                    placeholder="empty"
                    alt={`img-${imageIndex}`}
                  />
                ) : null}
                {imagesMobile?.[imageIndex]?.mediaItemUrl ? (
                  <Image
                    src={
                      imagesMobile[imageIndex]?.mediaItemUrl ||
                      "/images/404.png"
                    }
                    layout="fill"
                    objectFit="cover"
                    placeholder="empty"
                    alt={`img-${imageIndex}-1`}
                  />
                ) : null}
                <Image
                  src={
                    images[imageIndex]?.src ||
                    images[imageIndex]?.mediaItemUrl ||
                    images[imageIndex] ||
                    "/images/404.png"
                  }
                  placeholder="empty"
                  layout="fill"
                  objectFit="cover"
                  priority={true}
                  alt={`img-${imageIndex}-2`}
                />
              </motion.div>
            ) : (
              <motion.picture
                key={page}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0 },
                }}
              >
                {images?.[imageIndex]?.srcSet ? (
                  <Image
                    src={images[imageIndex]?.srcSet}
                    layout="fill"
                    objectFit="cover"
                    alt={`img-${imageIndex}-3`}
                  />
                ) : null}
                {imagesMobile?.[imageIndex]?.mediaItemUrl ? (
                  <Image
                    src={
                      imagesMobile[imageIndex]?.mediaItemUrl ||
                      "/images/404.png"
                    }
                    layout="fill"
                    objectFit="cover"
                    alt={`img-${imageIndex}-4`}
                  />
                ) : null}
                <Image
                  src={
                    images?.[imageIndex]?.src ||
                    images?.[imageIndex]?.mediaItemUrl ||
                    images?.[imageIndex] ||
                    "/images/404.png"
                  }
                  layout="fill"
                  objectFit="cover"
                  alt={`img-${imageIndex}-4`}
                />
              </motion.picture>
            )}
          </AnimatePresence>

          {images?.length > 1 ? (
            <div
              className={`prev`}
              onClick={() => {
                paginate(-1);
              }}
            ></div>
          ) : null}

          {images?.length > 1 ? (
            <div
              className={`next`}
              onClick={() => {
                paginate(1);
              }}
            ></div>
          ) : null}

          {images?.length > 1 ? (
            <div className={`counter ${hideCounter ? "hide-mobile" : ""}`}>
              <span>{`${imageIndex + 1} / ${images.length}`}</span>
              <Text element="span" className="title">
                {images[imageIndex]?.altText}
              </Text>
            </div>
          ) : null}
        </div>

        {images?.length > 1 ? (
          <div className="marker">
            {images.map((itm, index) => {
              return (
                <button
                  key={`gallery-${last(
                    itm?.mediaItemUrl?.split("/")
                  )}-${index}`}
                  className={index == imageIndex ? "selected" : undefined}
                  onClick={() => paginate(index - imageIndex)}
                ></button>
              );
            })}
          </div>
        ) : null}
      </div>
      {images?.map((itm, index) => (
        <link
          key={`preload-${last(itm?.mediaItemUrl?.split("/"))}-${index}`}
          rel="preload"
          href={`${itm.mediaItemUrl}`}
          as="image"
        ></link>
      ))}
    </>
  );
};

export default ImageComponent;
