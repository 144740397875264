import Link from "next/link";
import Image from "next/image";


import { getTranslation, getMenuTranslation } from "./methods/getTranslation";
import promoPic from '../../../abadia-retuerta-wp-connected/public/images/Pasillo-habitaciones-silla-resized.png';

const SubMenuHotel = ({
  activeSection,
  activeSubSection,
  setActiveSubSection,
  menu,
  type,
  isSticky,
  tourLink,
  tourText,
  t,
  i18n,
}) => {
  // console.log("SubMenuHotel", JSON.stringify(menu));

  return (
    <div
      className={`submenu hotel${
        activeSection == type || activeSubSection == type ? " open" : ""
      }${isSticky ? " sticky" : ""}`}
      onMouseEnter={() => setActiveSubSection(type)}
      onMouseLeave={() => setActiveSubSection(null)}
    >
      <div>
        <div className="left">
          {menu?.item.map((itm, index) => {
            // console.log("SubMenuHotel", getTranslation(itm, "uri", language));
            const isLeaders =
              itm?.externallink?.title == "Leaders Club" ||
              itm?.externallink?.title?.includes("Leader");
            const getLeaders = () => {
              if (isLeaders) {
                if (i18n.language == "en") {
                  return itm?.externallink?.url?.replace("//es", "//www");
                } else if (i18n.language == "de") {
                  return itm?.externallink?.url?.replace("//es", "//de");
                } else {
                  return itm?.externallink?.url;
                }
              }
            };
            return (
              <div key={`sub-menu-hotel-${index}`}>
                {itm.externallink ? (
                  itm?.link?.title == "Tour Virtual" ? (
                    <a href={tourLink} target={itm.externallink.target}>
                      {tourText}
                    </a>
                  ) : isLeaders ? (
                    <a href={getLeaders()} target={itm.externallink.target}>
                      {i18n?.language !== "es"
                        ? getTranslation(itm, "title", {
                            code: i18n.language?.toUpperCase(),
                          }) || itm.externallink.title
                        : itm.externallink.title}
                    </a>
                  ) : (
                    <a
                      href={itm.externallink.url}
                      target={itm.externallink.target}
                    >
                      {i18n?.language !== "es"
                        ? getTranslation(itm, "title", {
                            code: i18n.language?.toUpperCase(),
                          }) || itm.externallink.title
                        : itm.externallink.title}
                    </a>
                  )
                ) : (
                  <Link
                    prefetch={false}
                    href={getTranslation(itm, "uri", {
                      code: i18n.language?.toUpperCase(),
                    })}
                  >
                    <a onClick={() => setActiveSubSection(false)}>
                      {getTranslation(itm, "title", {
                        code: i18n.language?.toUpperCase(),
                      })}
                    </a>
                  </Link>
                )}
                <p>
                  {i18n?.language == "es"
                    ? itm.descripcionEn || itm.descripcion
                    : i18n?.language == "de"
                    ? itm.descripcionDe || itm.descripcion
                    : itm.descripcion}
                </p>
              </div>
            );
          })}
        </div>
        <div className="right">
         <Link
              prefetch={false}
              href={t("promo-4x3.link.url")}
              locale={false}
            >
              <a  onClick={() => setActiveSubSection(null)}>
                <div className="image">
                  <Image
                    layout="fill"
                    objectFit="cover"
                    objectPosition={'center'}
                    src={promoPic|| '/images/404.png'}
                    alt="promotion"
                  />
                </div>
                <span>
                 {t("promo-4x3.menu-text")}
                </span>

               
              </a>
            </Link>
        </div>
      </div>
    </div>
  );
};

export default SubMenuHotel;
