import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import Image from "next/image";

import { useTranslation } from "next-i18next";
import Link from "ab-link";
import Text from "ab-text";
import ParagraphText from "ab-paragraph-text";

import "./carrousel-home.scss";

const CarrouselHome = ({
  title = "Una experiencia gastronómica",
  text = [],
  content,
  contentFull,
  cards,
  className = "",
  classname = "",
  animated,
  singleColumn,
  singlecolumn,
  element = "h2",
  textClass,
  textclass,
  h1,
  navlink,
  i18n,
}) => {
  // console.log("links", links);
  // console.log("CarrouselHome", contentFull);
  const [viewMore, setViewMore] = useState(false);

  const myRef = useRef();
  const videoRef = useRef();
  const { t } = useTranslation("common", { i18n });

  useEffect(() => {
    const slider = document.querySelector("#carrousel-home");
    if (slider) {
      slider.addEventListener("scroll", () => {
        const video0 = document.querySelector("#video_0");
        const video1 = document.querySelector("#video_1");
        const video2 = document.querySelector("#video_2");
        const video3 = document.querySelector("#video_3");
        const hideVideo = (video) => {
          if (video) {
            video.style.opacity = 0;
          }
        };

        if (slider?.scrollLeft < window.innerWidth / 2 && video0) {
          video0.style.opacity = 1;
          video0.style.display = "flex";
          hideVideo(video1);
          hideVideo(video2);
          hideVideo(video3);
        }
        if (slider.scrollLeft > window.innerWidth / 2 && video1) {
          video1.style.opacity = 1;
          video1.style.display = "flex";
          hideVideo(video0);
          hideVideo(video2);
          hideVideo(video3);
        }

        if (
          slider.scrollLeft > window.innerWidth + window.innerWidth / 2 &&
          video2
        ) {
          video2.style.opacity = 1;
          video2.style.display = "flex";

          hideVideo(video0);
          hideVideo(video1);
          hideVideo(video3);
        }
        if (
          slider.scrollLeft > 2 * window.innerWidth + window.innerWidth / 2 &&
          video3
        ) {
          video3.style.opacity = 1;
          video3.style.display = "flex";
          hideVideo(video0);
          hideVideo(video1);
          hideVideo(video2);
        }
      });
    }
    return () => { };
  }, [content]);

  useEffect(() => {
    const slider = document.querySelector("#carrousel-home");
    // const circle = document.querySelector('#circle');

    let isDown = false;
    let startX;
    let scrollLeft;
    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
        slider.classList.remove("prevent-clic");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        slider.classList.add("prevent-clic");

        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
      slider.addEventListener("mouseover", (e) => {
        // if (circle) {
        //   circle.style.opacity = 1;
        //   circle.style.height = '70px';
        //   circle.style.width = '70px';
        // }
      });
    }

    document.addEventListener("mousemove", (e) => {
      // if (circle) {
      //   circle.style.left = e.pageX - 35 + 'px';
      //   circle.style.top = e.pageY - 40 + 'px';
      // }
    });
  }, []);

  const myRef2 = useRef();

  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef2,
    { threshold: 0.5 },
    {
      onEnterViewport: () => {
        // console.log("entered viewport");
      },
    },
    { disconnectOnLeave: false }
  );
  const handleScroll = () => {
    const element = document.getElementById(`menu-#${navlink}`);
    const menu = document.getElementById("history-menu");

    if (element) {
      if (inViewport) {
        if (!element?.classList?.contains("selected")) {
          const children = menu?.querySelectorAll("li");
          children.forEach(function (e) {
            if (e.id !== element.id) {
              e?.classList.remove("selected");
              e?.classList.add("not-selected");
            }
          });
          element?.classList.add("selected");
          element?.classList.remove("not-selected");
          menu.scrollLeft = element?.offsetLeft - 10;
        }
      } else {
        if (!element?.classList?.contains("not-selected")) {
          element?.classList.remove("selected");
          element?.classList.add("not-selected");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inViewport]);

  const renderCards = () => {
    return (
      <div className="row" id={"carrousel-home"} ref={myRef}>
        {cards?.map((card, index) => {
          // console.log("CarrouselHome cards", cards);

          return (
            <a href={card?.link?.url} key={card?.title} className="card">
              {card?.imagen?.mediaItemUrl ?
                <Image
                  src={card?.imagen?.mediaItemUrl || '/images/404.png'}
                  layout="fill"
                  alt={card?.title}
                />
                : null}

              <video
                className="image web"
                src={card?.video}
                id={`video_${index}`}
                ref={videoRef}
                loop
                playsInline
                muted
                autoPlay
                preload="auto"
                poster={card?.imagen?.mediaItemUrl}
                style={{
                  ...(!card?.video
                    ? {
                      opacity: 0.9,
                    }
                    : {}),
                }}
              />

              <Text className={"card-title"}>{card?.title}</Text>
              <Text className={"card-description"}>{card?.descripcion}</Text>
              <div className="arrow">
                <Image
                  src={"/images/arrow.png"}
                  alt={`Ver ${card?.title}`}
                  height={12}
                  width={15}
                />
              </div>
            </a>
          );
        })}
      </div>
    );
  };

  if (!singlecolumn && !singleColumn) {
    return (
      <div
        className={`carrousel-home horizontal ${className || classname || ""}`}
      >
        <div>
          {h1 ? <Text element="h1">{h1}</Text> : null}

          <Text
            element={element}
            animated={animated}
            className={textClass || textclass}
          >
            {title}
          </Text>

          <ParagraphText text={text} content={content} singleColumn={true} />
        </div>
        {cards?.length ? renderCards() : null}
        {/* <img src="/images/drag.png" id="circle"></img> */}
      </div>
    );
  }
  return (
    <>
      {navlink ? (
        <a className="reference" style={{ top: -90 }} id={navlink} />
      ) : null}

      <div
        className={`carrousel-home ${className || classname || ""}`}
        ref={myRef2}
      >
        {h1 ? <Text element="h1">{h1}</Text> : null}

        <Text
          element={element}
          animated={animated}
          className={textClass || textclass}
        >
          {title}
        </Text>

        <ParagraphText
          text={text}
          content={viewMore ? contentFull : content}
          singleColumn={singleColumn || singlecolumn}
        />

        {cards?.length ? renderCards() : null}

        {
          <ParagraphText
            text={text}
            className={"duplicate"}
            singleColumn={singleColumn || singlecolumn}
          />
        }
        {/* <img src="/images/drag.png" id="circle"></img> */}
        {contentFull ? (
          <Link
            prefetch={false}
            text={viewMore ? t("cerrar") : t("leer-mas")}
            style={{ alignSelf: "center", marginTop: 25 }}
            onClick={() => {
              setViewMore(!viewMore);
            }}
          ></Link>
        ) : null}
      </div>
    </>
  );
};

export default CarrouselHome;
