import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import NormalLink from "ab-link";

import { getTranslation } from "./methods/getTranslation";

const SubMenuExperiencias = ({
  activeSection,
  activeSubSection,
  setActiveSubSection,
  menu,
  type,
  isSticky,
  t,
  i18n,
}) => {
  return (
    <div
      className={`submenu experiencias${activeSection == type || activeSubSection == type ? " open" : ""
        }${isSticky ? " sticky" : ""}`}
      onMouseEnter={() => setActiveSubSection(type)}
      onMouseLeave={() => setActiveSubSection(null)}
    >
      <div>
        <div className="left">
          <span>{t("experiencias-unicas")}</span>

          <div>
            {menu?.experiencias.map((itm, index) => (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`sub-menu-experiences-${index}`}
              >
                <a onClick={() => setActiveSubSection(null)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            ))}

            <NormalLink
              text={t("ver-todas-experiencias")}
              href={t("/experiencias-unicas")}
            />
          </div>
        </div>
        <div className="right">
          {menu?.destacadas.map((itm, index) => (
            <Link
              prefetch={false}
              href={getTranslation(itm, "uri", {
                code: i18n.language?.toUpperCase(),
              })}
              key={`sub-menu-excperiences-featured-${index}`}
            >
              <a onClick={() => setActiveSubSection(null)}>
                <div className="image">
                  <Image
                    layout="fill"
                    src={itm?.link?.featuredImage?.node?.mediaItemUrl || '/images/404.png'}
                    alt={getTranslation(itm, "title", {
                      code: i18n.language?.toUpperCase(),
                    })}
                  />
                </div>
                <span>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </span>

                <p>{itm?.[`descripcion${i18n.language?.toUpperCase()}`]}</p>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubMenuExperiencias;
