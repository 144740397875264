const createNewProduct = (product, productPrice, qty) => {
  const subtotal = parseFloat((productPrice * qty).toFixed(2));

  return {
    databaseId: product.databaseId,
    image: product.image,
    name: product.name,
    slug: product.slug,
    type: product.type,
    price: productPrice,
    qty,
    attributes: product.attributes,
    subtotalPrice: subtotal,
    totalPrice: subtotal,
  };
};

export default createNewProduct;
