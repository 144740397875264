import Text from "ab-text";
import CardServices from "ab-card-services";
import CardCrossSelling from "ab-card-cross-selling";
import Link from "ab-link";
import ParagraphText from "ab-paragraph-text";
import { getTranslation } from "ab-functions";

import "./ab-cards.scss";

const Cards = ({
  title,
  text,
  cards = [],
  className = "",
  classname = "",
  link,
  animated,
  linkBefore,
  linkbefore,
  textStyle,
  i18n,
}) => {
  const getLabel = (itm) => {
    if (i18n.language == "en") {
      return itm.labelEn || itm.label;
    }
    if (i18n.language == "de") {
      return itm.labelDe || itm.label;
    }
    return itm.label;
  };

  const getTitle = (itm) => {
    if (i18n.language == "ens") {
      return itm.titleEn || itm.title;
    }
    if (i18n.language == "de") {
      return itm.titleDe || itm.title;
    }
    return itm.title;
  };

  return (
    <div className={`ab-cards ${className || ""} ${classname || ""}`}>
      <div className="title">
        <Text element="h2" animated={animated} style={textStyle}>
          {title}
        </Text>
        {text ? <ParagraphText text={[text]} singleColumn /> : null}
      </div>
      {link && (linkBefore || linkbefore) ? (
        <Link prefetch={false} {...link} className="before" />
      ) : null}
      <div className="row">
        {cards.map((itm, index) =>
          itm.title && itm.label && itm.href ? (
            <CardCrossSelling
              src={itm.src || itm.image.mediaItemUrl}
              srcSet={itm?.srcSet || itm.imagemobile?.mediaItemUrl}
              label={getLabel(itm)}
              title={getTitle(itm)}
              href={
                getTranslation({ link: itm.page }, "uri", {
                  code: i18n?.language?.toUpperCase(),
                }) || itm.href
              }
              key={`cross-card-${index}`}
              link={itm.link}
            />
          ) : (
            <CardServices
              src={itm.src || itm.image?.mediaItemUrl}
              srcSet={itm?.srcSet || itm.imagemobile?.mediaItemUrl}
              label={getLabel(itm)}
              href={
                getTranslation(
                  { link: itm.page },
                  "uri",
                  { code: i18n?.language?.toUpperCase() }
                ) || itm.href
              }
              key={`service-card-${index}`}
              link={itm.link}
            />
          )
        )}
      </div>

      {link && !linkBefore && !linkbefore ? (
        <Link
          prefetch={false}
          text={link.text || link.title}
          href={link.href || link.url}
          target={link.target}
        />
      ) : null}
    </div>
  );
};

const DisfruteAbadiaRetuerta = ({ className }) => (
  <Cards
    title="Disfrute de Abadía Retuerta"
    cards={[
      {
        src: "/images/descubra-nuestro-hotel-desk-x2.jpg",
        srcSet: "/images/descubra-nuestro-hotel-mobile-x2.jpg",
        label: "Descubra nuestro hotel",
        href: "/hotel-spa-valladolid-ribera-del-duero",
      },
      {
        src: "/images/explore-nuestra-bodega-desk-x2.jpg",
        srcSet: "/images/explore-nuestra-bodega-mobile-x2.jpg",
        label: "Explore nuestra bodega",
        href: "/tienda",
      },
      {
        src: "/images/disfrute-del-santuario-spa-_-wellness-desk-x2.jpg",
        srcSet: "/images/disfrute-del-santuario-spa-_-wellness-mobile-x2.jpg",
        label: "Disfrute del Santuario SPA & Wellness",
        href: "/santuario-spa-valladolid",
      },
    ]}
    className={className}
    animated
  />
);

const RegaleUnaExperiencia = ({ className }) => (
  <Cards
    title="Regale una experiencia"
    className={className}
    cards={[
      {
        src: "/images/aperitivo-campero-desk-381x381.jpg",
        srcSet: "/images/aperitivo-campero-mobile-222x250.jpg",
        label: "Experiencia Aperitivo Campero",
        href: "/tienda/regala-experiencias/aperitivo-campero",
      },
      {
        src: "/images/alojamiento-refectorio-desk-381x381.jpg",
        srcSet: "/images/alojamiento-refectorio-desk-222x250.jpg",
        label: "Experiencia Alojamiento y Refectorio",
        href: "/tienda/regala-experiencias/alojamiento-refectorio",
      },
      {
        src: "/images/alojamiento-bodega-desk-381x381.jpg",
        srcSet: "/images/alojamiento-bodega-desk-222x250.jpg",
        label: "Experiencia Alojamiento y Visita a la Bodega",
        href: "/tienda/regala-experiencias/alojamiento-visita-bodega",
      },
    ]}
    link={{
      text: "Ver Experiencias",
      href: "/tienda/regala-experiencias",
    }}
    linkBefore
    animated
  />
);

const ExperienciasUnicas = ({ className }) => (
  <Cards
    title="Experiencias únicas"
    className={className}
    cards={[
      {
        src: "/images/experiencias-de-temporada-desk-381x381.jpg",
        srcSet: "/images/experiencias-de-temporada-mobile-222x250.jpg",
        label: "Experiencias de temporada",
        href: "/experiencias-unicas/temporada",
      },
      {
        src: "/images/experiencias-a-medida-desk-381x381.jpg",
        srcSet: "/images/experiencias-a-medida-mobile-222x250.jpg",
        label: "Experiencias a medida",
        href: "/experiencias-unicas/experiencias-medida-personalizadas",
      },
      {
        src: "/images/regale-una-experiencia-desk-381x381.jpg",
        srcSet: "/images/regale-una-experiencia-mobile-222x250.jpg",
        label: "Regale una experiencia",
        href: "/tienda/regala-experiencias",
      },
    ]}
    animated
  />
);

const DisfruteDelWellness = ({ className }) => (
  <Cards
    title="Disfrute del Wellness en Abadía"
    className={className}
    cards={[
      {
        src: "/images/espacio-unico-desk-381x381.jpg",
        srcSet: "/images/espacio-unico-desk-222x250.jpg",
        label: "Un espacio único",
        href: "/santuario-spa-valladolid/instalaciones",
      },
      {
        src: "/images/spa-sommelier-desk-381x381.jpg",
        srcSet: "/images/spa-sommelier-desk-222x250.jpg",
        label: "SPA Sommelier",
        href: "/santuario-spa-valladolid/spa-sommelier",
      },
      {
        src: "/images/tratamientos-desk-381x381.jpg",
        srcSet: "/images/tratamientos-desk-222x250.jpg",
        label: "Tratamientos",
        href: "/santuario-spa-valladolid/tratamientos",
      },
    ]}
  />
);

const UnHotelAMedida = ({ className }) => (
  <Cards
    title="Un hotel a su medida"
    cards={[
      {
        src: "/images/habitaciones-desk-x2.jpg",
        srcSet: "/images/habitaciones-mobile-x2.jpg",
        label: "Habitaciones",
        href:
          "/hotel-spa-valladolid-ribera-del-duero/habitaciones-hotel-5-estrellas",
      },
      {
        src: "/images/servicios-desk-x2.jpg",
        srcSet: "/images/servicios-mobile-x2.jpg",
        label: "Servicios",
        href:
          "/hotel-spa-valladolid-ribera-del-duero/servicios-hotel-lujo-piscina",
      },
      {
        src: "/images/escapadas-ledomaine-desk-x2.jpg",
        srcSet: "/images/escapadas-ledomaine-mobile-x2.jpg",
        label: "Escapadas LeDomaine",
        href:
          "/hotel-spa-valladolid-ribera-del-duero/escapadas-ledomaine-hotel-5-estrellas",
      },
    ]}
    animated
    className={className}
  />
);

const GastronomiaDeAbadia = ({ className, link, linkBefore, refectorio }) => (
  <Cards
    title="La gastronomía de Abadía Retuerta"
    description
    cards={
      refectorio
        ? [
          {
            src: "/images/1-home-gastonomia-carrusel-x2-desk-840x840.jpg",
            srcSet:
              "/images/1-home-gastonomia-carrusel-x2-mobile-444X500.jpg",
            label: "WINEBAR ENTRE VIÑEDOS",
            title: "Calicata Terroir Bar",
            href: "/restaurantes-valladolid/calicata-terroir-bar",
          },
          {
            src: "/images/4-home-gastonomia-carrusel-x2-desk-840x840_.jpg",
            srcSet:
              "/images/4-home-gastonomia-carrusel-x2-mobile-444x500_.jpg",
            label: "Tradición y creatividad",
            title: "Vinoteca",
            href: "/restaurantes-valladolid/vinoteca",
          },
          {
            src: "/images/2-home-gastonomia-carrusel-x2-desk-840x840_.jpg",
            srcSet:
              "/images/2-home-gastonomia-carrusel-x2-mobile-444x500_.jpg",
            label: "Tradición y creatividad",
            title: "El Jardin del Claustro",
            href: "/restaurantes-valladolid/terraza-jardin-del-claustro",
          },
        ]
        : [
          {
            src: "/images/3-home-gastonomia-carrusel-x2-desk-840x840_.jpg",
            srcSet:
              "/images/3-home-gastonomia-carrusel-x2-mobile-444x500_.jpg",
            label: "cocina contemporánea de corte creativo",
            title: "Refectorio",
            href: "/restaurantes-valladolid/refectorio-estrella-michelin",
          },
          {
            src: "/images/4-home-gastonomia-carrusel-x2-desk-840x840_.jpg",
            srcSet:
              "/images/4-home-gastonomia-carrusel-x2-mobile-444x500_.jpg",
            label: "Tradición y creatividad",
            title: "Vinoteca",
            href: "/restaurantes-valladolid/vinoteca",
          },
          {
            src: "/images/2-home-gastonomia-carrusel-x2-desk-840x840_.jpg",
            srcSet:
              "/images/2-home-gastonomia-carrusel-x2-mobile-444x500_.jpg",
            label: "Tradición y creatividad",
            title: "El Jardin del Claustro",
            href: "/restaurantes-valladolid/terraza-jardin-del-claustro",
          },
        ]
    }
    animated
    className={className}
    link={link}
    linkBefore={linkBefore}
  />
);

export default Cards;

export {
  DisfruteAbadiaRetuerta,
  RegaleUnaExperiencia,
  ExperienciasUnicas,
  DisfruteDelWellness,
  UnHotelAMedida,
  GastronomiaDeAbadia,
};
