import fetchApi from "ab-fetch-api";
import { CART_INFO } from "ab-api-fragments";

export default async function addCartItems(items, token) {
  let variables = { variables: { items } };

  let query = `
  mutation addCartItems($items: [CartItemInput]) {
    addCartItems(input: {items: $items }) {
      ${CART_INFO}
    }
  }
  
    `;

  const data = await fetchApi(query, variables, token);

  if (data?.[0]) {
    return data;
  } else {
    return data?.addCartItems?.cart;
  }
}
