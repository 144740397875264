import { useEffect, useState } from "react";
import Image from "next/image";
import Text from "ab-text";
import Cookies from "js-cookie";
import "./popup.scss";

const PopUp = ({ content, cookieId, config={}}) => {
  const {sliderInterval}= config
  const [currentIdx, setCurrentIdx] = useState(0);
const {image, title, body, size} = content[currentIdx]
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    let sessionState = sessionStorage.getItem(cookieId);
    if (sessionState) {
      setShowPopup(false);
    } else {
      setShowPopup(true);
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIdx((prevIdx) => (prevIdx + 1) % content.length);
    }, sliderInterval);

    return () => clearInterval(interval);
  }, [content]);
  

  const closeModal = () => {
    sessionStorage.setItem(cookieId, "closed");
    Cookies.set(cookieId, "closed");
    setShowPopup(false);
    document.body.style.overflow = "initial";
  };
  return (
    <div
      className={`popup-cierre-modal ${showPopup ? "show" : ""}`}
      id={"modal-container"}
      onClick={(e) => closeModal()}
      role="button"
      tabIndex="0"
    >
      <div
        className={"popup-cierre-content"}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button className="popup-cierre-close" onClick={(e) => closeModal()}>
          +
        </button>

        <div className={"popup-cierre-form"}>
          <div className="popup-cierre-image-container" >
            <Image
              src={image}
              alt={"premios"}
              objectFit="contain"
              width={size.width}
              height={size.height}
              
            />
          </div>
        <div>
          <Text element="h3" className={"cierre-title gold"}>
            {title}
          </Text>
          <Text element="p">{body}</Text>
         </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
