import fetchApi from "ab-fetch-api";
import { ORDER_INFO } from "ab-api-fragments";

export async function getCustomerOrder(id, token) {
  const data = await fetchApi(
    `
    query getCustomerOrder($id: ID) {
      order(id: $id, idType: DATABASE_ID) {
        ${ORDER_INFO}
      }
    }
    
        
  `,
    {
      variables: {
        id,
      },
    },
    token
  );
  return data;
}
