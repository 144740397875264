import { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Numeral from "numeral";
import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";
import { useModalContext } from "ab-context-modal";
import { useEboxContext } from "ab-context-ebox";
import { useCartContext } from "ab-context-cart";

import Text from "ab-text";
import Input from "ab-input";
import Button from "ab-form-button";

import "./ab-form-dedicatoria.scss";
import { filter, find } from "lodash-es";
import { registerLocale } from "ab-functions";

const FormDedicatoria = ({
  i18n,
  products,
  setShowUpsellingModal,
  product,
}) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const [stateModal, dispatchMdoal] = useModalContext();
  const { showModal } = stateModal;
  const { setShowModal } = dispatchMdoal;
  const [stateEbox, dispatchEbox] = useEboxContext();
  const { itemsEbox } = stateEbox;
  const { addItemsToEbox, resetEbox, setDedicatoria } = dispatchEbox;
  const [state, dispatch] = useCartContext();
  const { addItemToCart } = dispatch;

  const { t } = useTranslation("common", { i18n });

  const router = useRouter();

  const showUpselling = product?.configuradorEbox?.upselling;

  const eboxFijas = product?.configuradorEbox?.fijas;
  const alojamiento1Noche = product?.configuradorEbox?.estancias?.length
    ? find(
        product?.configuradorEbox?.estancias,
        (product) => product?.producto?.sku == "EB00031"
      )?.producto
    : find(products, (product) => product?.sku == "EB00031");
  const alojamiento2Noche = product?.configuradorEbox?.estancias?.length
    ? find(
        product?.configuradorEbox?.estancias,
        (product) => product?.producto?.sku == "EB00032"
      )?.producto
    : find(products, (product) => product?.sku == "EB00032");
  const _products = filter(
    products,
    (product) => product?.price || product?.producto?.price
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      firstName: "",
      lastName: "",
      giftMsg: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(t("validationFirstName")),
      lastName: Yup.string().required(t("validationLastName")),
      giftMsg: Yup.string().required(t("validationDedicatoria")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      // formRef.current.submit();
    },
  });
  useEffect(() => {
    registerLocale();
    if (eboxFijas?.length) {
      if (alojamiento1Noche) {
        addItemsToEbox([...eboxFijas, alojamiento1Noche]);
      } else {
        addItemsToEbox(eboxFijas);
      }
    }
  }, []);
  useEffect(() => {
    if (eboxFijas?.length && itemsEbox?.length < eboxFijas?.length) {
      if (alojamiento1Noche) {
        addItemsToEbox([...eboxFijas, alojamiento1Noche]);
      } else {
        addItemsToEbox(eboxFijas);
      }
    }
  }, [product, eboxFijas, itemsEbox]);
  // if (eboxFijas?.length && itemsEbox?.length < eboxFijas?.length) {
  //   addItemsToEbox(eboxFijas);
  // }

  let tempPrice = 0;
  let tempPriceToShow = Numeral(0).value();

  const continuar =async () => {
    //añadir dedicatoria
    if (showUpselling) {
      setShowUpsellingModal(true);
      setDedicatoria({
        giftMsg: values?.giftMsg,
        giftName: values?.firstName,
        giftLastName: values?.lastName,
      });
    } else {
      let addons = [];
      setLoading(true);
      itemsEbox?.map((itm) => {
        tempPrice += Numeral(itm?.price).value();
        tempPriceToShow += Numeral(itm?.price).value();
        // console.log("estuchePrice", tempPriceToShow, itm);
      });
      addons.push({
        display: `${Numeral(tempPrice).format("1,000.00")}&euro;`,
        name: "Ebox - Price",
        price: tempPrice,
        value: tempPrice,
      });

      if (values?.giftMsg) {
        addons.push({
          name: "Dedicatoria Experience Box",
          price: "0",
          value: values?.giftMsg,
        });
      }

      if (values?.firstName && values?.lastName) {
        addons.push({
          name: "Beneficiario",
          price: "0",
          value: values?.firstName + " " + values?.lastName,
        });
      }

      addons.push({
        name: "Experiencias",
        price: "",
        value: itemsEbox.map((itm) => itm.sku).join(),
      });
      addons.push({
        name: "Que incluye",
        price: "",
        value: itemsEbox.map((itm) => itm.name).join(),
      });

      let extraData = {
        addons,
      };

     await addItemToCart(
        { databaseId: product?.databaseId, price: tempPriceToShow },
        1,
        null,
        JSON.stringify(extraData)
      );
      resetEbox();
      router.push(t("/cesta"));
    }
  };

  const saltar = async () => {
    // setShowModal(true);
    if (showUpselling) {
      setShowUpsellingModal(true);
    } else {
      let addons = [];
      setLoading(true);
      itemsEbox?.map((itm) => {
        tempPrice += Numeral(itm?.price).value();
        tempPriceToShow += Numeral(itm?.price).value();
        console.log("tempPriceToShow", tempPriceToShow, itm);
      });
      addons.push({
        display: `${Numeral(tempPrice).format("1,000.00")}&euro;`,
        name: "Ebox - Price",
        price: tempPrice,
        value: tempPrice,
      });
      addons.push({
        name: "Experiencias",
        price: "",
        value: itemsEbox.map((itm) => itm.sku).join(),
      });
      addons.push({
        name: "Que incluye",
        price: "",
        value: itemsEbox.map((itm) => itm.name).join(),
      });

      let extraData = {
        addons,
      };

    await  addItemToCart(
        { databaseId: product?.databaseId, price: tempPriceToShow },
        1,
        null,
        JSON.stringify(extraData)
      );
      resetEbox();
      router.push(t("/cesta"));
    }
  };

  const { values, setFieldValue, handleSubmit, errors } = formik;
  // console.log("Form dedicatoria - ", eboxFijas);

  return (
    <>
      <div className="form-dedicatoria">
        <Text element="h2" className="incluya">
          {t("dedicatoriaTitle")}
        </Text>

        <Input
          placeholder={t("dedicatoriaFirstName")}
          value={values.firstName}
          error={errors.firstName}
          onChange={(val) => setFieldValue("firstName", val)}
          id="m_first_name"
          maxLength="40"
          name="first_name"
        />
        <Input
          placeholder={t("dedicatoriaLastName")}
          value={values.lastName}
          error={errors.lastName}
          onChange={(val) => setFieldValue("lastName", val)}
          id="m_last_name"
          maxLength="80"
          data-testid={"last_name"}
          name="last_name"
        />

        <Input
          placeholder={t("dedicatoriaComments")}
          value={values.giftMsg}
          error={errors.giftMsg}
          onChange={(val) => setFieldValue("giftMsg", val)}
          id="m_giftMsg"
          maxLength="400"
          data-testid={"giftMsg"}
          name="giftMsg"
        />

        <Text element="p" className="obligatorios">
          {t("obligatorios")}
        </Text>

        <div className="row">
          <Button
            loading={loading}
            onClick={() => continuar()}
            disabled={!values?.firstName || !values.lastName}
          >
            {t("continue")}
          </Button>

          <Button loading={loading} onClick={() => saltar()}>
            {t("saltar")}
          </Button>
        </div>
      </div>

      <Text element="p" className="envios">
        {t("envios-dentro-de-espana")}
      </Text>
    </>
  );
};

export default FormDedicatoria;
