import Link from "next/link";
import { useTranslation } from "next-i18next";
import { getTranslation } from "./methods/getTranslation";

const SubMenuSpa = ({ setOpen, menu, t, i18n }) => {
  return (
    <div className={`submenu-inner hotel`}>
      {menu?.spa.links.map((itm) => (
        <Link
          prefetch={false}
          href={getTranslation(itm, "uri", {
            code: i18n.language?.toUpperCase(),
          })}
        >
          <a onClick={() => setOpen(false)}>
            {getTranslation(itm, "title", {
              code: i18n.language?.toUpperCase(),
            })}
          </a>
        </Link>
      ))}

      <span>{t("experiencias-wellness")}</span>
      {menu?.spa.experienciasWellness.map((itm) => (
        <Link
          prefetch={false}
          href={getTranslation(itm, "uri", {
            code: i18n.language?.toUpperCase(),
          })}
        >
          <a onClick={() => setOpen(false)}>
            {getTranslation(itm, "title", {
              code: i18n.language?.toUpperCase(),
            })}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default SubMenuSpa;
