import React, { useReducer, useEffect } from "react";

import {
  getAuthToken,
  getRefreshToken,
  mustLogout,
  hasExpiredAuthToken,
  getUserAuthId,
} from "ab-functions-auth";
import { refreshJwtAuthToken } from "ab-api-mutations";
import { getDatosPersonales as getDatos } from "ab-api-queries";

const defaultState = {
  user: null,
  userLanguage: null
};

import reducer from "./reducer";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const UserProvider = ({ children }) => {
  // const [state, dispatch] = useImmer({ ...defaultState });
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
  });

  useEffect(() => {
    if (state?.authToken && state?.customerId) {
      getDatosPersonales(state.customerId, state.authToken);
    }
  }, [state.authToken, state.customerId]);

  const getDatosPersonales = async (id, token) => {
    const data = await getDatos(id, token);

    if (data.status === 500) {
      dispatch({
        type: "logout",
        payload: data,
      });
    } else {
      dispatch({
        type: "set_user",
        payload: data,
      });
    }
  };

  const processAuthToken = () => {
    // console.log("processAuthToken");
    const authToken = getAuthToken();
    const refreshToken = getRefreshToken();
    // console.log("processAuthToken - auth, refresh - ", authToken, refreshToken);
    // Si no existen debemos forzar el logout
    if (!authToken || !refreshToken) {
      // console.log("processAuthToken - unregister");
      dispatch({
        type: "logout",
        payload: "soft",
      });
      return true;
    }

    // Si han pasado mas de 45 minutos sin actividad debemos forzar el logout
    if (mustLogout(authToken)) {
      // console.log("processAuthToken - must logout");
      dispatch({
        type: "logout",
        payload: "hard",
      });
      return true;
    }
    const authId = getUserAuthId();
    if (hasExpiredAuthToken(authToken)) {
      // console.log("processAuthToken - expired");
      refreshJwtAuthToken(refreshToken).then((r) => {
        if (r.status && r.status === 500) {
          dispatch({
            type: "logout",
            payload: "hard",
          });
        } else {
          const newToken = r.data.authToken;

          dispatch({
            type: "register_new_token",
            payload: newToken,
          });
          // if (state?.customerId) {
          //   getDatosPersonales(state?.customerId, newToken);
          // }
        }
      });
    } else {
      // console.log("processAuthToken - not expired", authToken);

      dispatch({
        type: "register_tokens",
        payload: {
          authToken,
          refreshToken,
          customerId: authId,
          rememberMe: !!localStorage.getItem(process.env.AUTH_TOKEN_NAME),
        },
      });
    }
  };
  const registerLang = () => {
    const lang =
      localStorage.getItem(process.env.DEFAULT_LANG);
    if (lang) {
      dispatch({
        type: "set_navigatorLanguage",
        payload: {
          userLanguage: lang
        },
      });
    }

  };
  useEffect(() => {
    processAuthToken();
    registerLang();
  }, []);

  // console.log("UserProvider - state - ", state);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export { defaultState, UserProvider, UserStateContext, UserDispatchContext };
