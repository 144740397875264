import { defaultState } from "./provider";
import Router from "next/router";

export const encodeToken = (token) => {
  if (!token) {
    return null;
  }

  return btoa(token);
};

export default function reducer(state, action) {
  // console.log("CheckoutReducer", state, action);

  switch (action.type) {
    case "set_step_0":
      return {
        ...state,
        user: {
          email: action.payload.email,
          password: action.payload.password,
          selected: action.payload.selected,
        },
        step: action.payload.step || state.step,
      };
    case "set_step_1":
      let tempValue = {
        ...state,
        billing: action.payload.checkout,
        customerNote: action?.payload?.checkout?.giftMsg,
        metaData: [
          {
            key: "particular_o_empresa",
            value: action?.payload?.checkout?.cif ? "empresa" : "particular",
          },
          {
            key: "billing_razon_social",
            value: action?.payload?.checkout?.company || "",
          },
          {
            key: "shipping_phone",
            value:
              action?.payload?.checkout?.shippingphone ||
              action?.payload?.checkout?.phone,
          },
          { key: "is_vat_exempt", value: "no" },
          {
            key: "nif",
            value:
              action?.payload?.checkout?.cif ||
              action.payload.checkout.dni ||
              "",
          },
        ],
        step: 2,
      };

      if (action?.payload?.checkout?.shippingSameAsBilling) {
        tempValue.shipping = {
          address1: action?.payload?.checkout?.address1,
          address2: action?.payload?.checkout?.address2,
          city: action?.payload?.checkout?.city,
          state: action?.payload?.checkout?.state,
          postcode: action?.payload?.checkout?.postcode,
          phone:
            action?.payload?.checkout?.shippingphone ||
            action?.payload?.checkout?.phone,
          firstName: action?.payload?.checkout?.firstName,
          lastName: action?.payload?.checkout?.lastName,
        };
      }
      return tempValue;
    case "checkout_complete":
      return {
        ...state,
        status: "complete",
      };
    case "method_selected":
      if (
        action.payload.method == "ppcp-gateway" &&
        action?.payload?.response?.status === "PROCESSING"
      ) {
        // localStorage.removeItem(process.env.CART_SESSION_NAME);
        // localStorage.removeItem("woo-session");
        Router.push(
          `/finalizar-compra/${action?.payload?.response?.orderNumber}`
        );
      }
      return {
        ...state,
        method: action.payload.method,
        step: state.step,
        response: action.payload.response,
      };
    case "step_back":
      return {
        ...state,
        step: state.step - 1,
      };
    case "reset_checkout":
      return {
        ...defaultState,
      };
    default:
      console.log("CheckoutReducer - error - action unknown", action);
  }
}
