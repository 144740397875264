import { useState } from "react";
import Link from "next/link";
import { useCartContext } from "ab-context-cart";
import { BookModal } from "ab-reservar";
import { getTranslation } from "./methods/getTranslation";

const LowerMenu = ({
  menu,
  activeSection,
  setActiveSection,
  activeSubSection,
  t,
  i18n,
}) => {
  const [showBookModal, setShowBookModal] = useState(false);

  const [cartState, cartDispatch] = useCartContext();
  const { contents } = cartState;

  return (
    <>
      <div className="lower">
        <nav>
          <Link href={t("/")}>
            <a></a>
          </Link>
          {menu?.menuPrincipal?.map((itm, index) => {

            // console.log('LowerMenu item', itm)
            return (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n?.language?.toUpperCase(),
                }) || 'https://www.abadia-retuerta.com'}
                key={`lower-menu-${index}`}
              >
                <div
                  onMouseEnter={() => setActiveSection(itm.type)}
                  onMouseLeave={() => {
                    setActiveSection(null);
                  }}
                  className={
                    activeSection == itm.type || activeSubSection == itm.type
                      ? "selected"
                      : undefined
                  }
                  onClick={() => setActiveSection(null)}
                >
                  {itm?.type == 'contacta' ? 'Corporate' :
                    getTranslation(itm, "title", {
                      code: i18n?.language?.toUpperCase(),
                    })}
                </div>
              </Link>
            )
          })}
        </nav>
      </div>
      <div className="quick-links">
        <div
          onClick={() => {
            setShowBookModal(true);
            document.body.style.overflow = "initial";
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.06177 6.27029H13.9444"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.9615 8.8738H10.9677"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.00322 8.8738H8.0094"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.03862 8.8738H5.0448"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.9615 11.4656H10.9677"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.00322 11.4656H8.0094"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.03862 11.4656H5.0448"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.6958 1.33398V3.52784"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.31027 1.33398V3.52784"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.8255 2.38672H5.18064C3.22285 2.38672 2 3.47734 2 5.48207V11.5152C2 13.5514 3.22285 14.6673 5.18064 14.6673H10.8193C12.7833 14.6673 14 13.5703 14 11.5656V5.48207C14.0062 3.47734 12.7895 2.38672 10.8255 2.38672Z"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Hotel
        </div>

        <Link prefetch={false} href={t("/cesta")}>
          <a>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0091 14.3348H5.44386C3.39962 14.3348 1.83135 13.5965 2.27681 10.6247L2.7955 6.59722C3.0701 5.11438 4.01595 4.54688 4.84585 4.54688H11.6315C12.4736 4.54688 13.3645 5.1571 13.6818 6.59722L14.2005 10.6247C14.5789 13.2608 13.0533 14.3348 11.0091 14.3348Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.1008 4.3998C11.1008 2.80909 9.81129 1.51956 8.22057 1.51956V1.51956C7.45457 1.51631 6.71883 1.81833 6.17604 2.35883C5.63325 2.89934 5.32812 3.63379 5.32813 4.3998H5.32812"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {contents?.itemCount ? contents?.itemCount : null} {t("cart")}
          </a>
        </Link>
      </div>

      <BookModal
        setShowModal={setShowBookModal}
        showModal={showBookModal}
        t={t}
        i18n={i18n}
      />
    </>
  );
};

export default LowerMenu;
