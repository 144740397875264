import React, { useRef } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";

import Text from "ab-text";
import Link from "ab-link";
import Tag from "ab-tag";
import { useInViewport } from "react-in-viewport";

import "./ab-home-bodega.scss";

const HomeBodega = ({
  imagencentro,
  imagenizquierda,
  imagenderecha,
  title,
  description,
  tag,
  className = "",
  classname = "",
  animated,
  links,
  i18n,
}) => {
  const myRef = useRef();
  const { t } = useTranslation("common", { i18n });
  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef,
    {},
    {
      onEnterViewport: () => {
        // console.log("entered viewport");
      },
    },
    { disconnectOnLeave: false }
  );
  // console.log('HomeBodega', description, links);
  return (
    <div className={`ab-home-bodega left ${className || classname || ""}`}>
      <div className="details">
        {tag ? <Tag>{tag}</Tag> : null}
        <Text element="h2" animated={animated}>
          {title}
        </Text>
      </div>
      <div className={`images ${inViewport ? "in-viewport" : ""}`} ref={myRef}>
        <div className="image-left">
          <Image
            src={imagenizquierda?.mediaItemUrl || '/images/404.png'}
            alt="Botella"
            width={148}
            height={600}
          />
        </div>

        <div className="image-right">
          <Image
            src={imagenderecha?.mediaItemUrl || '/images/404.png'}
            alt="Botella"
            width={148}
            height={600}
          />
        </div>

        <div className="image-center">
          <Image
            src={imagencentro?.mediaItemUrl || '/images/404.png'}
            alt="Botella"
            width={155}
            height={626}
          />
        </div>
      </div>
      <div className="right">
        <Text element="div">{description}</Text>

        {links && links.length ? (
          <div className="row web">
            {links.map((itm) => {
              return (
                <Link
                  key={itm?.link?.url}
                  prefetch={false}
                  text={itm?.link?.title}
                  href={itm?.link?.url}
                  target={itm?.link?.target}
                  className={"link"}
                />
              );
            })}
          </div>
        ) : null}
        <div className="mobile">
          <Link
            prefetch={false}
            text={t("descubre-nuestra-bodega")}
            href={t("/tienda")}
            className={"link"}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default HomeBodega;
