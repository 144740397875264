import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import Text from "ab-text";
import Link from "ab-link";
import Cookies from "js-cookie";
import { gtmSendCookie } from "ab-gtm";
import { useRouter } from "next/router";
import "./ab-cookies.scss";

const ABCookies = ({ i18n, t }) => {
  const [cookiePolicy, setCookiePolicy] = useState();
  // const [defaultLang, setDefaultCookieLang] = useState("ES");
  const router = useRouter();

  useEffect(() => {
    let tempCookiePolicy = localStorage.getItem("cookie-policy");

    // let tempCookiePolicy = Cookies.get("cookie-policy");
    // console.log(`tempCookiePolicy |${tempCookiePolicy}|`);
    if (tempCookiePolicy == "all" || tempCookiePolicy == "necessary") {
      setCookiePolicy(tempCookiePolicy);
    } else {
      setCookiePolicy("");
    }
    gtmSendCookie();
  }, []);

  // useEffect(() => {
  //   // console.log(router?.pathname);
  //   if (router?.pathname?.includes("/en")) {
  //     setDefaultCookieLang("EN");
  //   } else if (router?.pathname?.includes("/de")) {
  //     setDefaultCookieLang("DE");
  //   } else {
  //     setDefaultCookieLang("ES");
  //   }
  // }, [router?.pathname]);
  return (
    <div
      className={`ab-cookies ${cookiePolicy == "all" ||
        cookiePolicy == "necessary" ||
        cookiePolicy == null || cookiePolicy == undefined
        ? "hide"
        : "show"
        }`}
    >
      <div>
        <Text element="h3">{t("tuPrivacidad")}</Text>
        <Text>{t("utilizamosCookies")}</Text>
      </div>
      <div>
        <button
          onClick={() => {
            localStorage.setItem("cookie-policy", "all");
            Cookies.set("cookie-policy", "all");
            setCookiePolicy("all");
            gtmSendCookie();
          }}
        >
          {t("aceptarTodo")}
        </button>

        <Link
          prefetch={false}
          text={t("declinarTodas")}
          onClick={() => {
            localStorage.setItem("cookie-policy", "necessary");
            Cookies.set("cookie-policy", "necessary");
            setCookiePolicy("necessary");
            gtmSendCookie();
          }}
        />
      </div>
    </div>
  );
};

export default ABCookies;
