import fetchApi from "ab-fetch-api";
import { CART_INFO } from "ab-api-fragments";

export default async function addToCart(productId, quantity, token, extraData) {
  let variables = { variables: { productId, quantity } };

  if (extraData) {
    variables.variables.extraData = extraData;
  }

  let query = `
  mutation addToCart($productId: Int!, $quantity: Int!, $extraData: String) {
    addToCart(input: {productId: $productId, quantity: $quantity, extraData: $extraData}) {
      ${CART_INFO}
    }
  }
  
    `;

  const data = await fetchApi(query, variables, token);

  if (data?.[0]) {
    return data;
  } else {
    return data?.addToCart?.cart;
  }
}
