import { useContext } from "react";
import Router from "next/router";
import Cookies from "js-cookie";

import { first } from "lodash-es";

import {
  getDatosPersonales as getDatos,
  getCart as getCartQuery,
} from "ab-api-queries";
import {
  loginUser as loginUserMutation,
  registerUser as registerUserMutation,
  updateUser as updateUserQuery,
} from "ab-api-mutations";

import { UserStateContext, UserDispatchContext } from "./provider";

const useUserStateContext = () => {
  const state = useContext(UserStateContext);

  if (state === undefined) {
    throw new Error("State must be defined");
  }

  return state;
};

const useUserDispatchContext = () => {
  const dispatch = useContext(UserDispatchContext);
  const state = useContext(UserStateContext);

  // useEffect(() => {
  //   if (state?.authToken && state?.customerId) {
  //     getDatosPersonales(state.customerId, state.authToken);
  //   }
  // }, [state.authToken, state.customerId]);

  const getDatosPersonales = async (id, token) => {
    const data = await getDatos(id, token);

    if (data.status === 500) {
      dispatch({
        type: "logout",
        payload: data,
      });
    } else {
      dispatch({
        type: "set_user",
        payload: data,
      });
    }
  };

  const loginUser = (email, password, locale, rememberMe, stopRedirect) => {
    return loginUserMutation(email, password).then((res) => {
      // console.log("loginUserResponse", res);
      localStorage.setItem("woo-session", res?.customer?.sessionToken);

      if (res.errors?.length) {
        if (first(res.errors)?.message == "invalid_email") {
          return locale == "en"
            ? "No user found with that email"
            : "No existe un usuario registrado con ese email.";
        } else if (first(res.errors)?.message === "incorrect_password") {
          return locale == "en"
            ? "Password incorrect"
            : "La contraseña introducida no es correcta";
        }
      } else {
        dispatch({
          type: "register_tokens",
          payload: {
            authToken: res.authToken,
            refreshToken: res.refreshToken,
            customerId: res.customer.id,
            rememberMe: rememberMe,
            role: res.customer.role,
          },
        });

        getDatosPersonales(res?.customer?.id, res?.authToken);

        if (!stopRedirect) {
          Router.push(locale == "es" ? "/mi-cuenta" : "/en/my-account");
        }
      }
    });
  };

  const preLoginUser = (email, password, locale, rememberMe, stopRedirect) => {
    return loginUserMutation(email, password)
      .then((res) => {
        console.log("preLoginUser", res);

        // email existe si
        //  first(res.errors)?.message : "incorrect_password",
        // si el email no existe da este error
        //  first(res.errors)?.message : "invalid_email",

        // DEVOLVER TRUE SOLO SI no hay error o el error es:   "incorrect_password",

        if (res.errors?.length) {
          if (first(res.errors)?.message == "incorrect_password") {
            // el email existe pero la password es incorrecta
            return true;
          }
          // el email no existe
          return false;
        } else {
          // no hay error el login es correcto
          return true;
        }
      })
      .catch(() => {
        return false;
      });
  };

  const getCart = async (token) => {
    const res = await getCartQuery(token);
    // console.log("getCartResponse", res);
  };

  const registerUser = (email, password, firstName, lastName, locale) => {
    return registerUserMutation(email, password, firstName, lastName, locale)
      .then((res) => {
        // console.log("registerUser response", res);

        if (res.errors?.length) {
          // console.log(
          //   "registerUser res?.errors?.length",
          //   first(res?.errors)?.message
          // );

          return first(res?.errors)?.message;
        } else {
          const authToken = res?.authToken;
          const customer = res?.customer;
          const refreshToken = res?.refreshToken;

          getDatosPersonales(customer.id, authToken);
          dispatch({
            type: "register_tokens",
            payload: {
              authToken,
              refreshToken,
              customerId: customer?.id,
            },
          });
          Router.push(locale == "es" ? "/mi-cuenta" : "/en/my-account");
        }
      })
      .catch((e) => {
        // console.log("registerUser catch", e);
      });
  };

  const updateUser = (authToken, refreshToken, customerId) => {
    dispatch({
      type: "register_tokens",
      payload: {
        authToken,
        refreshToken,
        customerId: customerId,
      },
    });
  };

  const setLanguage = (language) => {
    Cookies.set(process.env.DEFAULT_LANG, language);

    // i18n.changeLanguage(language?.toLowerCase());

    dispatch({
      type: "set_navigatorLanguage",
      payload: {
        userLanguage: language,
      },
    });
  };

  const logout = (lang) => {
    dispatch({
      type: "logout",
    });

    Router.push(lang == "EN" ? "/en/login" : "/login");
  };

  return {
    ...state,
    getDatosPersonales,
    loginUser,
    preLoginUser,
    logout,
    registerUser,
    setLanguage,
    updateUser,
  };
};

const useUserContext = () => {
  return [useUserStateContext(), useUserDispatchContext()];
};

export { useUserContext };
