import fetchApi from "ab-fetch-api";
import { CART_INNER } from "ab-api-fragments";

export async function getCart(token) {
  let query = `
  query getCart {
    cart {
      ${CART_INNER}
    }
  }
  
    `;

  const data = await fetchApi(query, {}, token);

  if (data?.[0]) {
    return data;
  } else {
    return data?.cart;
  }
}
