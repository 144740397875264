import Link from "next/link";

import { getTranslation } from "./methods/getTranslation";

const SubMenuCorporate = ({ setOpen, menu, t, i18n }) => {
  console.log('SubMenuCorporateInner', menu)
  return (
    <div className={`submenu-inner hotel`}>
      {menu?.contacta?.item.map((itm) => {
        if (itm.externallink) {
          return <a
            href={itm.externallink.url}
            target={itm.externallink.target}
          >
            {i18n?.language !== "es"
              ? getTranslation(itm, "title", {
                code: i18n.language?.toUpperCase(),
              }) || itm.externallink.title
              : itm.externallink.title}
          </a>
        }
        return <Link
          prefetch={false}
          href={getTranslation(itm, "uri", {
            code: i18n.language?.toUpperCase(),
          })}
        >
          <a onClick={() => setOpen(false)}>
            {getTranslation(itm, "title", {
              code: i18n.language?.toUpperCase(),
            })}
          </a>
        </Link>
      })}
    </div>
  );
};

export default SubMenuCorporate;
