import React, { useEffect, useState } from "react";
import Link from "next/link";

import dayjs from "dayjs";
import { gtmSendComprobarDisponibilidad } from "ab-gtm";

import Calendar from "ab-calendar";
import InputQuantity from "ab-input-quantity";

import getBookURL from "./helpers/getBookURL";
import "./ab-book-modal.scss";

const BookModal = ({ showModal, setShowModal, t, i18n }) => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "days"));
  const [guests, setGuests] = useState(1);
  const [rooms, setRooms] = useState(1);
  const [showPromo, setShowPromo] =useState(false);
useEffect(()=>{
const dif= endDate.diff(startDate, 'day');
setShowPromo([2, 3].includes(dif));
},[startDate, endDate])
  const locale =
    i18n.language == "en" ? "en-US" : i18n.language == "de" ? "de-DE" : "es-ES";

  return (
    <div
      className={`book-modal ${showModal ? "show" : ""}`}
      id={"modal-container"}
      onClick={(e) => {
        setShowModal(false);
        document.body.style.overflow = "initial";
      }}
      role="button"
      tabIndex="0"
    >
      <div
        className={"book-content"}
        onClick={(e) => {
          // console.log("ModalContent onclick");
          e.stopPropagation();
        }}
      >
        <button
          className="book-close"
          onClick={(e) => {
            setShowModal(false);

            document.body.style.overflow = "initial";

            e.stopPropagation();
          }}
        >
          +
        </button>

        <div className={"book-form"}>
          <div className="book-field">
            <label>{t("llegada")}</label>
            <Calendar
              value={startDate}
              onChange={(value) => {
                setStartDate(value);
                if (endDate && dayjs(value).isAfter(dayjs(endDate))) {
                  setEndDate(dayjs(value).add(1, "days"));
                }
              }}
              minimumDate={dayjs()}
              openDown
              i18n={i18n}
            />
          </div>
          <div className="book-field">
            <label>{t("salida")}</label>
            <Calendar
              value={endDate}
              onChange={setEndDate}
              minimumDate={startDate}
              openDown
              i18n={i18n}
            />
          </div>
          <div className="book-field">
            <label>{t("huespedes")}</label>

            <InputQuantity
              label={t("cuantos-huespedes")}
              value={guests}
              className={"no-border"}
              // error={errors.guests}
              onChange={(val) => setGuests(val)}
              i18n={i18n}
            />
          </div>
          <div className="book-field">
            <label>{t("rooms")}</label>

            <InputQuantity
              label={t("cuantas")}
              value={rooms}
              className={"no-border"}
              // error={errors.guests}
              onChange={(val) => setRooms(val)}
              i18n={i18n}
            />
            <div className="promo-container">
              {
               showPromo &&
               <p className="promo">{t("promo-4x3.text")} <Link href={t("promo-4x3.link.url")} locale={false}>{t("promo-4x3.link.text")}</Link></p>
              }
              </div>
          </div>
         
          <button
            className="ab-form-button"
            onClick={() => {
              gtmSendComprobarDisponibilidad(
                dayjs(startDate).format("YYYY-MM-DD"),
                dayjs(endDate).format("YYYY-MM-DD"),
                guests,
                rooms
              );
            }}
          >
            <a
              href={getBookURL(guests, startDate, endDate, rooms, locale)}
              target="_blank"
            >
              {t("disponibilidad")}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookModal;
