import Text from "ab-text";
import { useState } from "react";

import "./ab-input.scss";

const Input = ({
  type = "text",
  value,
  onChange,
  className,
  label,
  placeholder,
  error,
  parentClassName,
  placeHolderClassname,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const renderInput = () => {
    if (parentClassName == "at-novartis") {
      return (
        <div className="row">
          <input
            className={`ab-input${className ? ` ${className}` : ""}${
              error ? " error" : ""
            }`}
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            {...rest}
          />
          <input
            className={`ab-input${className ? ` ${className}` : ""}${
              error ? " error" : ""
            }`}
            type={type}
            value={
              process.env.STAGE == "prod" ? "@novartis.com" : "@habitant.es"
            }
            placeholder={placeholder}
            {...rest}
            disabled={true}
          />
        </div>
      );
    }
    return (
      <input
        className={`ab-input${className ? ` ${className}` : ""}${
          error ? " error" : ""
        } ${placeHolderClassname || ""}`}
        type={showPassword ? "text" : type}
        value={value}
        onChange={(e) =>
          type !== "checkbox" && type !== "radio"
            ? onChange(e.target.value)
            : onChange(e.target.checked)
        }
        checked={value}
        placeholder={placeholder}
        {...rest}
      />
    );
  };

  // if (type == "text" || type == "password") {
  //   return renderInput();
  // }

  return (
    <label className={`ab-input container ${type} ${parentClassName}`}>
      <Text>{label}</Text>
      {renderInput()}

      {type == "password" ? (
        <div
          className="password"
          role="button"
          onClick={() => setShowPassword(!showPassword)}
        >
          <img
            src={showPassword ? "/images/hide.png" : "/images/show.png"}
            style={{ width: 16, height: 16 }}
            alt={showPassword ? "hide" : "show"}
          />
        </div>
      ) : null}
      <span className="checkmark"></span>
      {error ? <span className="error">{error}</span> : null}
    </label>
  );
};

export default Input;
