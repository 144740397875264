import QuantityPicker from "ab-quantity-picker";

import "./ab-input-quantity.scss";

const InputQuantity = ({
  label,
  value,
  onChange,
  error,
  className,
  ...rest
}) => {
  return (
    <>
      <div
        className={`ab-input-quantity ${error ? "error" : ""} ${
          className ? className : ""
        }`}
      >
        {label}
        <QuantityPicker
          quantity={value}
          setQuantity={onChange}
          allowZero
          suffix={""}
          {...rest}
        />
      </div>
    </>
  );
};
export default InputQuantity;
