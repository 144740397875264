import addFirstProduct from "./addFirstProduct";
import calculateSalePrice from "./calculateSalePrice";
import createNewProduct from "./createNewProduct";
import getFloatVal from "./getFloatVal";
import getUpdatedProducts from "./getUpdatedProducts";
import isProductInCart from "./isProductInCart";
import removeItemFromCart from "./removeItemFromCart";
import updateCart from "./updateCart";
import { getTranslation } from "./getTranslation";
import getRedirectLanguage from "./getRedirectLanguage";
import { getLocaleFromContext } from "./getLocaleFromContext";
import getLinkFromTranslation from "./getLinkFromTranslation";
import registerLocale from "./registerLocale";
import parseWCPriceToFloat from "./parseWCPriceToFloat";
import parseFloatToPrice from "./parseFloatToPrice";
export {
  addFirstProduct,
  calculateSalePrice,
  createNewProduct,
  getFloatVal,
  getUpdatedProducts,
  isProductInCart,
  removeItemFromCart,
  updateCart,
  getTranslation,
  getRedirectLanguage,
  getLocaleFromContext,
  getLinkFromTranslation,
  registerLocale,
  parseWCPriceToFloat,
  parseFloatToPrice,
};
