import { ORDER_INFO } from "./order-info";
import { PRODUCT_INFO } from "./product-info";
import { CARDS_CONTENT } from "./cards-content";
import { CARDSSLIDER_CONTENT } from "./cards-slider-content";
import { CARROUSEL_HOME_CONTENT } from "./carrousel-home-content";
import { CARROUSEL_FULL_CONTENT } from "./carrousel-full-content";
import { CARROUSEL_HALF_CONTENT } from "./carrousel-half-content";
import { CART_INFO, CART_INNER } from "./cart-info";
import { CARTA_CONTENT } from "./carta-content";
import { CARRITO } from "./carrito";
import { CHEF_CONTENT } from "./chef-content";
import { CTA_CONTENT } from "./cta-content";
import { DESCUBRA_DOUBLE_CONTENT } from "./descubra-double-content";
import { DETALLES_CONTENT } from "./detalles-content";
import { DOUBLE_CONTENT } from "./double-content";
import { ESCAPADAS_DETAIL_DOS_CONTENT } from "./escapadas-detail-dos-content";
import { EXPERIENCIA_DETAIL } from "./experiencia-detail";
import { EXPERTOS_CONTENT } from "./expertos-content";
import { FAQ_CONTENT } from "./faq-content";
import { FORM_EVENTOS } from "./form-eventos";
import { FORM_EXPERIENCIAS } from "./form-experiencias";
import { FORM_SPA } from "./form-spa";
import { HERO_CONTENT } from "./hero-content";
import { HIGHLIGHT_CONTENT } from "./highlight-content";
import { HISTORIACARD_CONTENT } from "./historia-content";
import { HISTORIAMENU_CONTENT } from "./historia-menu";
import { HOMEBODEGA_CONTENT } from "./home-bodega-content";
import { HOME_CONTENT } from "./home-content";
import { INFORMES_CONTENT } from "./informes-content";
import { METODOLOGIA_CONTENT } from "./metodologia-content";
import { NOVARTIS_NAVIDAD } from "./novartis-navidad-info";
import { PAGE_WITH_TRANSLATION } from "./page-with-translation";
import { PRODUCT_CATEGORY } from "./product-category";
import { PRODUCT_DETAIL } from "./product-detail";
import { PRODUCT_ROWS } from "./product-rows";
import { SELECTEBOX_CONTENT } from "./select-e-box";
import { SERVICES_CONTENT } from "./services-content";
import { SIMPLE_PRODUCT_INFO } from "./simple-product-info";
import { TESTIMONIOS } from "./testimonios";
import { TEXT_PHOTO_CONTENT } from "./text-photo-content";

export {
  CARDS_CONTENT,
  CARDSSLIDER_CONTENT,
  CARROUSEL_HOME_CONTENT,
  CARROUSEL_FULL_CONTENT,
  CARROUSEL_HALF_CONTENT,
  CART_INFO,
  CART_INNER,
  CARTA_CONTENT,
  CARRITO,
  CHEF_CONTENT,
  CTA_CONTENT,
  DESCUBRA_DOUBLE_CONTENT,
  DETALLES_CONTENT,
  DOUBLE_CONTENT,
  ESCAPADAS_DETAIL_DOS_CONTENT,
  EXPERIENCIA_DETAIL,
  EXPERTOS_CONTENT,
  FAQ_CONTENT,
  FORM_EVENTOS,
  FORM_EXPERIENCIAS,
  FORM_SPA,
  HERO_CONTENT,
  HIGHLIGHT_CONTENT,
  HISTORIACARD_CONTENT,
  HISTORIAMENU_CONTENT,
  HOMEBODEGA_CONTENT,
  HOME_CONTENT,
  INFORMES_CONTENT,
  METODOLOGIA_CONTENT,
  NOVARTIS_NAVIDAD,
  PAGE_WITH_TRANSLATION,
  PRODUCT_CATEGORY,
  PRODUCT_DETAIL,
  PRODUCT_ROWS,
  SELECTEBOX_CONTENT,
  SERVICES_CONTENT,
  SIMPLE_PRODUCT_INFO,
  TESTIMONIOS,
  TEXT_PHOTO_CONTENT,
  ORDER_INFO,
  PRODUCT_INFO,
};
