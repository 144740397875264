import { SIMPLE_PRODUCT_INFO } from "./simple-product-info";

export const CART_INNER = `
appliedCoupons {
  code
  discountAmount
  discountTax
}
contents {
  nodes {
    key
    extraData {
      key
      id
      value
    }
    product {
      node {
        attributes {
          nodes {
            name
            options
          }
        }
        databaseId
        description(format: RAW)
        image {
          mediaItemUrl
        }
        galleryImages {
          nodes {
            mediaItemUrl
          }
        }
        id
        link
        name
        sku
        ... on SimpleProduct {
          ${SIMPLE_PRODUCT_INFO}
        }
      }
    }
    quantity
    subtotal
    subtotalTax
    tax
    total
  }
  itemCount
  productCount
}
discountTotal
discountTax
total
totalTax
subtotal
subtotalTax
shippingTax
shippingTotal
`;

export const CART_INFO = `
cart {
    ${CART_INNER}
  }
`;
