import { useState } from "react";
import dayjs from "dayjs";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useTranslation } from "next-i18next";
import CTA from "ab-cta";
import Calendar from "ab-calendar";
import { gtmSendComprobarDisponibilidad } from "ab-gtm";
import getBookURL from "./helpers/getBookURL";

import Cantidad from "./Cantidad";
import BookModal from "./BookModal";

import "./ab-reservar.scss";

const Reservar = ({ hotel, i18n }) => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "days"));
  const [guests, setGuests] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [showBookModal, setShowBookModal] = useState(false);

  const { t } = useTranslation("common", { i18n });

  const locale =
    i18n?.language?.toUpperCase() == "EN"
      ? "en-US"
      : i18n?.language?.toUpperCase() == "DE"
      ? "de-DE"
      : "es-ES";
  const { scrollYProgress } = useViewportScroll();
  const yPosAnim = useTransform(
    scrollYProgress,
    [0, 0.1, 0.2],
    [0, -550, -1000]
  );

  // console.log("ab-reservar", t("huespedes"), t("rooms"), i18n);

  return (
    <motion.div className="ab-reservar" style={{ y: yPosAnim }}>
      <div className="field">
        <label>{t("llegada")}</label>
        <Calendar
          value={startDate}
          onChange={(value) => {
            setStartDate(value);
            if (endDate && dayjs(value).isAfter(dayjs(endDate))) {
              setEndDate(dayjs(value).add(1, "days"));
            }
          }}
          minimumDate={dayjs()}
          i18n={i18n}
        />
      </div>
      <div className="field">
        <label>{hotel ? t("hora") : t("salida")}</label>
        <Calendar
          value={endDate}
          onChange={setEndDate}
          minimumDate={startDate}
          i18n={i18n}
        />
      </div>
      <div className="field">
        <label>{hotel ? t("guests") : t("huespedes")}</label>
        <Cantidad
          label={t("cuantos")}
          units={t("huespedes")}
          value={guests}
          onChange={setGuests}
          unit={t("huesped")}
          i18n={i18n}
        />
      </div>
      <div className="field">
        <label>{hotel ? t("Donde") : t("rooms")}</label>
        <Cantidad
          label={t("cuantas")}
          units={t("rooms")}
          unit={t("room")}
          value={rooms}
          onChange={setRooms}
          i18n={i18n}
        />
      </div>

      <div className="submit">
        <button
          className="reserva ab-cta"
          onClick={() => {
            gtmSendComprobarDisponibilidad(
              dayjs(startDate).format("YYYY-MM-DD"),
              dayjs(endDate).format("YYYY-MM-DD"),
              guests,
              rooms
            );
          }}
        >
          <a
            href={getBookURL(guests, startDate, endDate, rooms, locale)}
            target="_blank"
          >
            {t("reserve")}
          </a>
        </button>
      </div>

      <CTA
        onClick={() => setShowBookModal(true)}
        type="reserva"
        text={t("reserve")}
        href={getBookURL(guests, startDate, endDate, rooms, locale)}
        target="_blank"
      />
      <BookModal
        setShowModal={setShowBookModal}
        showModal={showBookModal}
        t={t}
        i18n={i18n}
      />
    </motion.div>
  );
};

export default Reservar;
export { BookModal };
