import fetchApi from "ab-fetch-api";
import { CART_INFO } from "ab-api-fragments";

export default async function getCart(token) {
  const data = await fetchApi(
    `
      query getCart {
        ${CART_INFO}
      }
  
    `,
    {},
    token
  );

  if (data?.[0]) {
    return data;
  } else {
    return data.cart;
  }
}
