import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const registerJwtToken = (authToken, refreshToken, userId) => {
  localStorage.setItem(process.env.USER_ID, encodeToken(userId));
  localStorage.setItem(process.env.AUTH_TOKEN_NAME, encodeToken(authToken));
  Cookies.set(process.env.REFRESH_TOKEN_NAME, encodeToken(refreshToken), {
    HttpOnly: false,
    expires: 365,
  });
};

export const unregisterJwtToken = () => {
  localStorage.removeItem(process.env.USER_ID);
  localStorage.removeItem(process.env.AUTH_TOKEN_NAME);
  Cookies.remove(process.env.REFRESH_TOKEN_NAME);
};

export const registerNewAuthToken = (authToken) => {
  localStorage.setItem(process.env.AUTH_TOKEN_NAME, encodeToken(authToken));
};

// /* Procesa el auth token:
//  * Si no existen los tokens se fuerza el logout
//  * Si han pasado mas de 45 minutos de inactividad se fuerza el logout
//  * Si el token auth ha expirado (15 minutos) se genera uno nuevo con el refresh token
//  * Si tenemos token, se llama al callback
//  */
// export const processAuthToken = (callback) => {
//   console.log("processAuthToken");
//   const authToken = getAuthToken();
//   const refreshToken = getRefreshToken();
//   // Si no existen debemos forzar el logout
//   if (!authToken || !refreshToken) {
//     console.log("processAuthToken - unregister");
//     unregisterJwtToken();
//     callback();
//     return;
//   }

//   // Si han pasado mas de 45 minutos sin actividad debemos forzar el logout
//   if (mustLogout(authToken)) {
//     console.log("processAuthToken - must logout");
//     unregisterJwtToken();
//     callback();
//     return;
//   }

//   if (hasExpiredAuthToken(authToken)) {
//     console.log("processAuthToken - expired");
//     refreshJwtAuthToken(refreshToken).then((r) => {
//       if (r.status && r.status === 500) {
//         unregisterJwtToken();
//       } else {
//         const newToken = r.data.authToken;

//         registerNewAuthToken(newToken);
//         callback(newToken);
//       }
//     });
//   } else {
//     console.log("processAuthToken - not expired");
//     callback(authToken);
//   }
// };

export const getAuthToken = () => {
  if (
    localStorage.getItem(process.env.AUTH_TOKEN_NAME) ||
    sessionStorage.getItem(process.env.AUTH_TOKEN_NAME)
  ) {
    return decodeToken(
      localStorage.getItem(process.env.AUTH_TOKEN_NAME) ||
        sessionStorage.getItem(process.env.AUTH_TOKEN_NAME)
    );
  }

  return null;
};

export const getRefreshToken = () => {
  if (Cookies.get(process.env.REFRESH_TOKEN_NAME)) {
    return decodeToken(Cookies.get(process.env.REFRESH_TOKEN_NAME));
  }
  return null;
};

export const hasExpiredAuthToken = (authToken) => {
  const authTokenDecoded = jwt_decode(authToken);
  const expiredAt = authTokenDecoded.exp;

  return getCurrentTimestamp() > expiredAt;
};

export const mustLogout = (authToken) => {
  const authTokenDecoded = jwt_decode(authToken);
  const expiredAt = authTokenDecoded.exp;

  const timestampExtra = 30 * 60; // 30 minutos

  return getCurrentTimestamp() > expiredAt + timestampExtra;
};

export const getCustomerId = () => {
  const authTokenDecoded = jwt_decode(getAuthToken());

  return parseInt(authTokenDecoded.data.user.id);
};

export const getUserAuthId = () => {
  return decodeToken(
    localStorage.getItem(process.env.USER_ID) ||
      sessionStorage.getItem(process.env.USER_ID)
  );
};

export const isLogged = () => {
  return getAuthToken() !== null && getUserAuthId() !== null;
};

export const getCurrentTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

export const encodeToken = (token) => {
  if (!token) {
    return null;
  }

  return btoa(token);
};

export const decodeToken = (token) => {
  if (!token) {
    return null;
  }

  return atob(token);
};
