import {
  gtmProductImpressions,
  gtmSendUserId,
  gtmSendComprobarDisponibilidad,
  gtmSendBotonesHeader,
  gtmSendContact,
  gtmSendRegister,
  gtmSendLogin,
  gtmSendCookie,
  gtmSendAddToCart,
  gtmSendRemoveFromCart,
  gtmSendCheckoutStep0,
  gtmSendCheckoutStep1,
  gtmSendCheckoutStep2,
  gtmSendCheckoutStep3,
  gtmSendPurchase,
  gtmSendContactExperiencia
} from "./datalayer";

export {
  gtmProductImpressions,
  gtmSendUserId,
  gtmSendComprobarDisponibilidad,
  gtmSendBotonesHeader,
  gtmSendContact,
  gtmSendRegister,
  gtmSendLogin,
  gtmSendCookie,
  gtmSendAddToCart,
  gtmSendRemoveFromCart,
  gtmSendCheckoutStep0,
  gtmSendCheckoutStep1,
  gtmSendCheckoutStep2,
  gtmSendCheckoutStep3,
  gtmSendPurchase,
  gtmSendContactExperiencia
};
