import { PRODUCT_INFO } from "./product-info";

export const NOVARTIS_NAVIDAD = `
... on Page_Contenidoflexible_Modulos_NovartisNavidad {
    fieldGroupName
    producto {
        ... on Product {
        ${PRODUCT_INFO}
        }
    }
    pdfProducto {
        mediaItemUrl
    }
    successpedido
}
`;
