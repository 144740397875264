import React, { useReducer } from "react";

const defaultState = {
  showModal: false,
  link: null,
  modalChildren: null,
};

import reducer from "./reducer";
import Modal from "./Modal";
const ModalStateContext = React.createContext();
const ModalDispatchContext = React.createContext();

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
  });

  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>
        <Modal state={state} dispatch={dispatch} />
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export { defaultState, ModalProvider, ModalStateContext, ModalDispatchContext };
