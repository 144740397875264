import fetchApi from "ab-fetch-api";

export async function getPosts(variables) {
  const data = await fetchApi(
    `
    query getPosts($first: Int, $last: Int, $before: String, $after: String, $where: RootQueryToPostConnectionWhereArgs) {
      posts(first: $first, last: $last, before: $before, after: $after, where: $where) {
        nodes {
          title
          slug
          date
          featuredImage {
            node {
              id
              mediaItemUrl
            }
          } 
          contenidoFlexible {
            fieldGroupName
            modulos {
              ... on Post_Contenidoflexible_Modulos_BlogPost {
                excerpt
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    
      
      `,
    {
      variables,
    }
  );
  return data;
}
