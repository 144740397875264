export const SIMPLE_PRODUCT_INFO = `
          databaseId
          price
          stockQuantity
          stockStatus
          manageStock
          metaData {
             value
            key
          }
          productos_ingles {
            contentIngles
          }
          productos_aleman {
            contentAleman
          }
          image {
            mediaItemUrl
          }
        `;
