import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";

import Text from "ab-text";
import Link from "ab-link";

import "./hero.scss";

const Hero = ({
  title,
  titulo,
  subtitle,
  subTitulo,
  image,
  imageMobile,
  imagemobile,
  video,
  videoMobile,
  i18n,
}) => {
  const [isIOS, setIOS] = useState(false);
  const [isHome, setIsHome] = useState(true);

  const [videoWebEl, setvideoWeb] = useState({});
  const [volumeWeb, setvolumeWeb] = useState(0);
  const [videoMobileEl, setvideoMobile] = useState({});
  const [volumeMobile, setvolumeMobile] = useState(0);
  const { t } = useTranslation("common", { i18n });
  // console.log("language", title, t("404-title"));
  useEffect(() => {
    const _videoWebEl = document.getElementsByTagName("video")[0];
    const _videoMobileEl = document.getElementsByTagName("video")[1];
    setvideoWeb(_videoWebEl);
    setvideoMobile(_videoMobileEl);

    if (_videoWebEl) {
      _videoWebEl.volume = 0;
      setvolumeWeb(0);
    }
    if (_videoMobileEl) {
      _videoMobileEl.volume = 0;
      _videoMobileEl.muted = true;
      setvolumeMobile(0);
    }
  }, [video, videoMobile]);

  useEffect(() => {
    setIOS(
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        ((navigator.userAgent.includes("Mac") ||
          navigator.userAgent.includes("iPhone")) &&
          "ontouchend" in document)
    );
    if (
      (i18n.language == "es" &&
        window.location.pathname?.split("/")?.length > 2) ||
      (i18n.language == "en" &&
        !window.location.pathname?.includes("en/home")) ||
      (i18n.language == "de" && !window.location.pathname?.includes("de/start"))
    ) {
      setIsHome(false);
    }
  }, []);
  // console.log("ab-hero", t("rooms"), t("huespedes"));
  return (
    <div className={`hero${title == "404" ? " not-found" : ""}`}>
      {video || videoMobile ? (
        <>
          <video
            className="image web"
            src={video.mediaItemUrl || video}
            id={"video"}
            loop
            autoPlay
            playsInline
            preload="auto"
            poster={image?.mediaItemUrl}
            style={{
              transform: "translate(0px, 0px)",
            }}
          />
          <video
            className="image mobile"
            src={videoMobile?.mediaItemUrl || video}
            loop
            id={"video-mobile"}
            muted={isIOS ? true : false}
            autoPlay
            playsInline
            preload="auto"
            poster={image?.mediaItemUrl}
            style={{
              transform: "translate(0px, 0px)",
            }}
          />
          <button
            className="volume-web"
            style={{
              bottom: isHome ? 120 : 40,
            }}
            onClick={() => {
              if (videoWebEl) {
                if (videoWebEl?.volume > 0) {
                  setvolumeWeb(0);
                  videoWebEl.volume = 0;
                } else {
                  setvolumeWeb(1);
                  videoWebEl.volume = 1;
                }
              }
            }}
          >
            <Image
              src={
                videoWebEl?.volume > 0
                  ? "/images/volumen-on.svg"
                  : "/images/volumen-off.svg"
              }
              height={36}
              width={36}
              alt="volumen"
            />
          </button>
          <button
            className="volume-mobile"
            style={{
              bottom: isHome ? 120 : 30,
            }}
            onClick={() => {
              // let videomobile = document.getElementsByTagName("video")[1];

              if (videoMobileEl) {
                if (!videoMobileEl?.muted) {
                  setvolumeMobile(0);
                  videoMobileEl.volume = 0;
                  videoMobileEl.muted = true;
                } else {
                  setvolumeMobile(1);
                  videoMobileEl.muted = false;
                  videoMobileEl.volume = 1;
                }
              }
            }}
          >
            <Image
              src={
                videoMobileEl?.muted
                  ? "/images/volumen-off.svg"
                  : "/images/volumen-on.svg"
              }
              height={26}
              width={26}
              alt="volumen"
            />
          </button>
        </>
      ) : (
        <>
          <Image
            src={image?.mediaItemUrl || image || "/images/404.png"}
            layout="fill"
            objectFit="cover"
            style={{
              opacity: 0.6,
              zIndex: -1,
            }}
            id="hero-desktop"
            alt="portada"
          />
          <Image
            src={
              imageMobile ||
              imagemobile?.mediaItemUrl ||
              image?.mediaItemUrl ||
              "/images/404.png"
            }
            objectFit="cover"
            layout={"fill"}
            style={{
              opacity: 0.6,
              zIndex: -1,
            }}
            id="hero-mobile"
            alt="portada"
          />
        </>
      )}
      <Text element="h2" className="hero-text">
        {title || titulo}
      </Text>
      {title == "404" ? (
        <>
          <Text element="h2">{t("404-title")}</Text>
          <Text>{t("404-text")}</Text>

          <Link prefetch={false} text={t("404-volver")} href={t("/")} />
        </>
      ) : (
        <Text element="span" className="title">
          {subtitle || subTitulo}
        </Text>
      )}
    </div>
  );
};

export default Hero;
