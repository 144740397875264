import fetchApi from "ab-fetch-api";
import {
  CARDS_CONTENT,
  CARDSSLIDER_CONTENT,
  CARROUSEL_HOME_CONTENT,
  CARROUSEL_FULL_CONTENT,
  CARROUSEL_HALF_CONTENT,
  CARTA_CONTENT,
  CHEF_CONTENT,
  CTA_CONTENT,
  DESCUBRA_DOUBLE_CONTENT,
  DETALLES_CONTENT,
  DOUBLE_CONTENT,
  ESCAPADAS_DETAIL_DOS_CONTENT,
  EXPERIENCIA_DETAIL,
  EXPERTOS_CONTENT,
  FAQ_CONTENT,
  FORM_EVENTOS,
  FORM_EXPERIENCIAS,
  FORM_SPA,
  HERO_CONTENT,
  HIGHLIGHT_CONTENT,
  HOME_CONTENT,
  HOMEBODEGA_CONTENT,
  HISTORIACARD_CONTENT,
  HISTORIAMENU_CONTENT,
  INFORMES_CONTENT,
  METODOLOGIA_CONTENT,
  PRODUCT_CATEGORY,
  PRODUCT_DETAIL,
  PRODUCT_ROWS,
  SELECTEBOX_CONTENT,
  SERVICES_CONTENT,
  TESTIMONIOS,
  TEXT_PHOTO_CONTENT,
  CARRITO,
  PAGE_WITH_TRANSLATION,
  NOVARTIS_NAVIDAD,
} from "ab-api-fragments";

export async function getPage(slug) {
  // console.log(
  //   "getOnePage",
  //   slug,
  //   process.env.WORDPRESS_API_URL,
  //   `
  // query postBy($slug: String!) {
  //   nodeByUri(uri: $slug) {
  //     ... on NodeWithTitle {
  //       title
  //       ... on Page {
  //         seo {
  //           title
  //           description
  //           keywords
  //         }
  //         language {
  //           name
  //           code
  //         }
  //         translations {
  //           title
  //           link
  //           language {
  //             code
  //           }
  //         }
  //         contenidoFlexible {
  //           fieldGroupName
  //           modulos {
  //             ${HERO_CONTENT}
  //             ${HOME_CONTENT}
  //             ${CARDS_CONTENT}
  //             ${CARDSSLIDER_CONTENT}
  //             ${HIGHLIGHT_CONTENT}
  //             ${HOMEBODEGA_CONTENT}
  //             ${HISTORIACARD_CONTENT}
  //             ${HISTORIAMENU_CONTENT}
  //             ${CARROUSEL_HOME_CONTENT}
  //             ${CARROUSEL_FULL_CONTENT}
  //             ${CARROUSEL_HALF_CONTENT}
  //             ${TEXT_PHOTO_CONTENT}
  //             ${SELECTEBOX_CONTENT}
  //             ${SERVICES_CONTENT}
  //             ${ESCAPADAS_DETAIL_DOS_CONTENT}
  //             ... on Page_Contenidoflexible_Modulos_Reservar {
  //               fieldGroupName
  //               type
  //             }
  //             ${DETALLES_CONTENT}
  //             ${CARTA_CONTENT}
  //             ${FAQ_CONTENT}
  //             ${CHEF_CONTENT}
  //             ${CTA_CONTENT}
  //             ... on Page_Contenidoflexible_Modulos_Disfrute {
  //               fieldGroupName
  //               classname
  //             }
  //             ... on Page_Contenidoflexible_Modulos_CrossDisfruteDelWellness {
  //               fieldGroupName
  //               classname
  //             }
  //             ... on Page_Contenidoflexible_Modulos_CrossExperienciasUnicas {
  //               fieldGroupName
  //               classname
  //             }
  //             ... on Page_Contenidoflexible_Modulos_CrossRegaleUnaExperiencia {
  //               fieldGroupName
  //               classname
  //             }
  //             ... on Page_Contenidoflexible_Modulos_CrossGastronomiaDeAbadia {
  //               fieldGroupName
  //               classname
  //             }
  //             ... on Page_Contenidoflexible_Modulos_CrossUnHotelASuMedida {
  //               fieldGroupName
  //               classname
  //             }
  //             ... on Page_Contenidoflexible_Modulos_CrossNuestrasSugerencias {
  //               fieldGroupName
  //               classname
  //             }
  //             ${DOUBLE_CONTENT}
  //             ${EXPERIENCIA_DETAIL}
  //             ... on Page_Contenidoflexible_Modulos_Contacta {
  //               fieldGroupName
  //             }
  //             ... on Page_Contenidoflexible_Modulos_BlogHeader {
  //               fieldGroupName
  //               title
  //               subtitle
  //             }
  //             ... on Page_Contenidoflexible_Modulos_BlogPost {
  //               fieldGroupName
  //               content
  //               excerpt
  //               classname
  //             }
  //             ${DESCUBRA_DOUBLE_CONTENT}
  //             ... on Page_Contenidoflexible_Modulos_Mantra {
  //               fieldGroupName
  //               image {
  //                 mediaItemUrl
  //               }
  //               imagemobile {
  //                 mediaItemUrl
  //               }
  //               title
  //               contenido
  //             }
  //             ... on Page_Contenidoflexible_Modulos_Historia {
  //               fieldGroupName
  //               title
  //               subtitle
  //               content
  //             }
  //             ... on Page_Contenidoflexible_Modulos_Dependencias {
  //               fieldGroupName
  //               title
  //               cards {
  //                 title
  //                 description
  //                 image {
  //                   mediaItemUrl
  //                 }
  //                 imagemobile {
  //                   mediaItemUrl
  //                 }
  //               }
  //             }
  //             ${EXPERTOS_CONTENT}
  //             ${INFORMES_CONTENT}
  //             ... on Page_Contenidoflexible_Modulos_Terruno {
  //               fieldGroupName
  //               title
  //               body
  //               righttitle
  //               list {
  //                 item
  //               }
  //             }
  //             ${METODOLOGIA_CONTENT}
  //             ... on Page_Contenidoflexible_Modulos_HanConfiado {
  //               fieldGroupName
  //               title
  //               logos {
  //                 mediaItemUrl
  //               }
  //             }
  //             ${TESTIMONIOS}
  //             ${PRODUCT_CATEGORY}
  //             ${PRODUCT_ROWS}
  //             ${PRODUCT_DETAIL}
  //             ${FORM_EXPERIENCIAS}
  //             ${FORM_EVENTOS}
  //             ${FORM_SPA}
  //             ${CARRITO}
  //             ${NOVARTIS_NAVIDAD}
  //             ... on Page_Contenidoflexible_Modulos_CrossNoticias {
  //               fieldGroupName
  //               classname
  //               title
  //               text
  //               link {
  //                 title
  //                 url
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //     modulosCross {
  //       modCrossDisfrute {
  //         animated
  //         cards {
  //           label
  //           labelEn
  //           labelDe
  //           image {
  //             mediaItemUrl
  //           }
  //           imagemobile {
  //             mediaItemUrl
  //           }
  //           page {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         classname
  //         fieldGroupName
  //         text
  //         title
  //         titleEn
  //         titleDe
  //       }
  //       modCrossExperiencias {
  //         animatedexperiencias
  //         cardsexperiencias {
  //           label
  //           labelEn
  //           labelDe
  //           image {
  //             mediaItemUrl
  //           }
  //           imagemobile {
  //             mediaItemUrl
  //           }
  //           page {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         classnameexperiencias
  //         fieldGroupName
  //         textexperiencias
  //         titleexperiencias
  //         titleexperienciasen
  //         titleexperienciasde
  //       }
  //       modCrossGastronomia {
  //         animatedgastronomia
  //         cardsgastronomia {
  //           label
  //           labelEn
  //           labelDe
  //           title
  //           titleEn
  //           titleDe
  //           image {
  //             mediaItemUrl
  //           }
  //           imagemobile {
  //             mediaItemUrl
  //           }
  //           page {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         classnamegastronomia
  //         fieldGroupName
  //         textgastronomia
  //         titlegastronomia
  //         titlegastronomiaen
  //         titlegastronomiade
  //       }
  //       modCrossHotel {
  //         animatedhotel
  //         cardshotel {
  //           label
  //           labelEn
  //           labelDe
  //           image {
  //             mediaItemUrl
  //           }
  //           imagemobile {
  //             mediaItemUrl
  //           }
  //           page {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         classnamehotel
  //         fieldGroupName
  //         texthotel
  //         titlehotel
  //         titlehotelen
  //         titlehotelde
  //       }
  //       modCrossRegaleExperiencia {
  //         animatedregale
  //         cardsregale {
  //           label
  //           labelEn
  //           labelDe
  //           image {
  //             mediaItemUrl
  //           }
  //           imagemobile {
  //             mediaItemUrl
  //           }
  //           page {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         classnameregale
  //         fieldGroupName
  //         textregale
  //         titleregale
  //         titleregaleen
  //         titleregalede
  //       }
  //       modCrossWellness {
  //         animatedwellness
  //         cardswellness {
  //           label
  //           labelEn
  //           labelDe
  //           image {
  //             mediaItemUrl
  //           }
  //           imagemobile {
  //             mediaItemUrl
  //           }
  //           page {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         classnamewellness
  //         fieldGroupName
  //         textwellness
  //         titlewellness
  //         titlewellnessen
  //         titlewellnessde
  //       }
  //       modCrossSugerencias {
  //         classnamesugerencias
  //         titlesugerencias
  //         titlesugerenciasen
  //         titlesugerenciasde
  //         products {
  //           product {
  //             ... on SimpleProduct {
  //               name
  //               slug

  //               attributes {
  //                 nodes {
  //                   name
  //                   options
  //                 }
  //               }
  //               type
  //               price
  //               image {
  //                 mediaItemUrl
  //               }
  //             }
  //             ... on GroupProduct {
  //               name
  //               slug
  //               attributes {
  //                 nodes {
  //                   name
  //                   options
  //                 }
  //               }
  //               type
  //               price
  //               image {
  //                 mediaItemUrl
  //               }
  //               products {
  //                 nodes {
  //                   name
  //                   productos {
  //                     subtitulo
  //                   }
  //                   attributes {
  //                     nodes {
  //                       name
  //                       options
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     headerFooter {
  //       footer {
  //         backgroundImage {
  //           mediaItemUrl
  //         }
  //         logo {
  //           mediaItemUrl
  //         }
  //         logoMobile {
  //           mediaItemUrl
  //         }
  //         organizations {
  //           link
  //           logo {
  //             mediaItemUrl
  //           }
  //         }
  //         legal {
  //           link {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //         instagram
  //         facebook
  //         twitter
  //         linkedin
  //         tripadvisor
  //         copyright
  //       }
  //       menu {
  //         hotel {
  //           item {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //             externallink {
  //               target
  //               title
  //               url
  //             }
  //             descripcion
  //             descripcionEn
  //             descripcionDe
  //           }
  //         }
  //         bodega {
  //           vinos {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //           categorias {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //           productosDestacados {
  //             producto {
  //               ... on SimpleProduct {
  //                 name
  //                 slug
  //                 featuredImage {
  //                   node {
  //                     mediaItemUrl
  //                   }
  //                 }
  //                 productos_ingles {
  //                   subtituloIngles
  //                 }
  //                 productos_aleman {
  //                   subtituloAleman
  //                 }
  //               }
  //               ... on GroupProduct {
  //                 name
  //                 slug
  //                 featuredImage {
  //                   node {
  //                     mediaItemUrl
  //                   }
  //                 }
  //                 productos_ingles {
  //                   subtituloIngles
  //                 }
  //                 productos_aleman {
  //                   subtituloAleman
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         gastronomia {
  //           restaurante {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //             descripcion
  //             descripcionEn
  //             descripcionDe
  //             imagen {
  //               mediaItemUrl
  //             }
  //           }
  //         }
  //         spa {
  //           links {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //           experienciasWellness {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //         }
  //         experienciasUnicas {
  //           experiencias {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //           destacadas {
  //             descripcionEn
  //             descripcionEs
  //             descripcionDe
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //         }
  //         conozcanos {
  //           links {
  //             link {
  //               ${PAGE_WITH_TRANSLATION}
  //             }
  //           }
  //         }
  //         menuSuperior {
  //           tour {
  //             textoen
  //             textoes
  //             textode
  //             link
  //             linken
  //             linkde
  //           }
  //           noticias {
  //             texten
  //             textes
  //             textde
  //             link {
  //               url
  //             }
  //           }
  //           reserve {
  //             textoen
  //             textoes
  //             textode
  //             link
  //           }
  //         }
  //         menuPrincipal {
  //           type
  //           link {
  //             ${PAGE_WITH_TRANSLATION}
  //           }
  //         }
  //       }
  //     }

  // }

  // `
  // );
  const data = await fetchApi(
    `
    query postBy($slug: String!) {
      nodeByUri(uri: $slug) {
        ... on NodeWithTitle {
          title
          ... on Page {
            seo {
              title
              description
              keywords
              noindex
            }
            language {
              name
              code
            }
            translations {
              title
              link
              language {
                code
              }
            }
            contenidoFlexible {
              fieldGroupName
              modulos {
                ${HERO_CONTENT}
                ${HOME_CONTENT}
                ${CARDS_CONTENT}
                ${CARDSSLIDER_CONTENT}
                ${HIGHLIGHT_CONTENT}
                ${HOMEBODEGA_CONTENT}
                ${HISTORIACARD_CONTENT}
                ${HISTORIAMENU_CONTENT}
                ${CARROUSEL_HOME_CONTENT}
                ${CARROUSEL_FULL_CONTENT}
                ${CARROUSEL_HALF_CONTENT}
                ${TEXT_PHOTO_CONTENT}
                ${SELECTEBOX_CONTENT}
                ${SERVICES_CONTENT}
                ${ESCAPADAS_DETAIL_DOS_CONTENT}
                ... on Page_Contenidoflexible_Modulos_Reservar {
                  fieldGroupName
                  type
                }
                ${DETALLES_CONTENT}
                ${CARTA_CONTENT}
                ${FAQ_CONTENT}
                ${CHEF_CONTENT}
                ${CTA_CONTENT}
                ... on Page_Contenidoflexible_Modulos_Disfrute {
                  fieldGroupName
                  classname
                }
                ... on Page_Contenidoflexible_Modulos_CrossDisfruteDelWellness {
                  fieldGroupName
                  classname
                }
                ... on Page_Contenidoflexible_Modulos_CrossExperienciasUnicas {
                  fieldGroupName
                  classname
                }
                ... on Page_Contenidoflexible_Modulos_CrossRegaleUnaExperiencia {
                  fieldGroupName
                  classname
                }
                ... on Page_Contenidoflexible_Modulos_CrossGastronomiaDeAbadia {
                  fieldGroupName
                  classname
                }
                ... on Page_Contenidoflexible_Modulos_CrossUnHotelASuMedida {
                  fieldGroupName
                  classname
                }
                ... on Page_Contenidoflexible_Modulos_CrossNuestrasSugerencias {
                  fieldGroupName
                  classname
                }
                ${DOUBLE_CONTENT}
                ${EXPERIENCIA_DETAIL}
                ... on Page_Contenidoflexible_Modulos_Contacta {
                  fieldGroupName
                }
                ... on Page_Contenidoflexible_Modulos_BlogHeader {
                  fieldGroupName
                  title
                  subtitle
                }
                ... on Page_Contenidoflexible_Modulos_BlogPost {
                  fieldGroupName
                  content
                  excerpt
                  classname
                }
                ${DESCUBRA_DOUBLE_CONTENT}
                ... on Page_Contenidoflexible_Modulos_Mantra {
                  fieldGroupName
                  image {
                    mediaItemUrl
                  }
                  imagemobile {
                    mediaItemUrl
                  }
                  title
                  contenido
                }
                ... on Page_Contenidoflexible_Modulos_Historia {
                  fieldGroupName
                  title
                  subtitle
                  content
                }
                ... on Page_Contenidoflexible_Modulos_Dependencias {
                  fieldGroupName
                  title
                  cards {
                    title
                    description
                    image {
                      mediaItemUrl
                    }
                    imagemobile {
                      mediaItemUrl
                    }
                  }
                }
                ${EXPERTOS_CONTENT}
                ${INFORMES_CONTENT}
                ... on Page_Contenidoflexible_Modulos_Terruno {
                  fieldGroupName
                  title
                  body
                  righttitle
                  list {
                    item
                  }
                }
                ${METODOLOGIA_CONTENT}
                ... on Page_Contenidoflexible_Modulos_HanConfiado {
                  fieldGroupName
                  title
                  logos {
                    mediaItemUrl
                  }
                }
                ${TESTIMONIOS}
                ${PRODUCT_CATEGORY}
                ${PRODUCT_ROWS}
                ${PRODUCT_DETAIL}
                ${FORM_EXPERIENCIAS}
                ${FORM_EVENTOS}
                ${FORM_SPA}
                ${CARRITO}
                ${NOVARTIS_NAVIDAD}
                ... on Page_Contenidoflexible_Modulos_CrossNoticias {
                  fieldGroupName
                  classname
                  title
                  text
                  link {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
      
        modulosCross {
          modCrossDisfrute {
            animated
            cards {
              label
              labelEn
              labelDe
              image {
                mediaItemUrl
              }
              imagemobile {
                mediaItemUrl
              }
              page {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            classname
            fieldGroupName
            text
            title
            titleEn
            titleDe
          }
          modCrossExperiencias {
            animatedexperiencias
            cardsexperiencias {
              label
              labelEn
              labelDe
              image {
                mediaItemUrl
              }
              imagemobile {
                mediaItemUrl
              }
              page {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            classnameexperiencias
            fieldGroupName
            textexperiencias
            titleexperiencias
            titleexperienciasen
            titleexperienciasde
          }
          modCrossGastronomia {
            animatedgastronomia
            cardsgastronomia {
              label
              labelEn
              labelDe
              title
              titleEn
              titleDe
              image {
                mediaItemUrl
              }
              imagemobile {
                mediaItemUrl
              }
              page {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            classnamegastronomia
            fieldGroupName
            textgastronomia
            titlegastronomia
            titlegastronomiaen
            titlegastronomiade
          }
          modCrossHotel {
            animatedhotel
            cardshotel {
              label
              labelEn
              labelDe
              image {
                mediaItemUrl
              }
              imagemobile {
                mediaItemUrl
              }
              page {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            classnamehotel
            fieldGroupName
            texthotel
            titlehotel
            titlehotelen
            titlehotelde
          }
          modCrossRegaleExperiencia {
            animatedregale
            cardsregale {
              label
              labelEn
              labelDe
              image {
                mediaItemUrl
              }
              imagemobile {
                mediaItemUrl
              }
              page {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            classnameregale
            fieldGroupName
            textregale
            titleregale
            titleregaleen
            titleregalede
          }
          modCrossWellness {
            animatedwellness
            cardswellness {
              label
              labelEn
              labelDe
              image {
                mediaItemUrl
              }
              imagemobile {
                mediaItemUrl
              }
              page {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            classnamewellness
            fieldGroupName
            textwellness
            titlewellness
            titlewellnessen
            titlewellnessde
          }
          modCrossSugerencias {
            classnamesugerencias
            titlesugerencias
            titlesugerenciasen
            titlesugerenciasde
            products {
              product {
                ... on SimpleProduct {
                  name
                  slug
                  
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                  price
                  image {
                    mediaItemUrl
                  }
                }
                ... on GroupProduct {
                  name
                  slug
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                  price
                  image {
                    mediaItemUrl
                  }
                  products {
                    nodes {
                      name
                      productos {
                        subtitulo
                      }
                      attributes {
                        nodes {
                          name
                          options
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
     
        headerFooter {
          footer {
            backgroundImage {
              mediaItemUrl
            }
            logo {
              mediaItemUrl
            }
            logoMobile {
              mediaItemUrl
            }
            organizations {
              link
              logo {
                mediaItemUrl
              }
            }
            legal {
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
            instagram
            facebook
            twitter
            linkedin
            tripadvisor
            copyright
          }
          menu {
            hotel {
              item {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
                externallink {
                  target
                  title
                  url
                }
                descripcion
                descripcionEn
                descripcionDe
              }
            }
            bodega {
              vinos {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
              categorias {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
              productosDestacados {
                producto {
                  ... on SimpleProduct {
                    name
                    slug
                    featuredImage {
                      node {
                        mediaItemUrl
                      }
                    }
                    productos_ingles {
                      subtituloIngles
                    }
                    productos_aleman {
                      subtituloAleman
                    }
                  }
                  ... on GroupProduct {
                    name
                    slug
                    featuredImage {
                      node {
                        mediaItemUrl
                      }
                    }
                    productos_ingles {
                      subtituloIngles
                    }
                    productos_aleman {
                      subtituloAleman
                    }
                  }
                }
              }
            }
            gastronomia {
              restaurante {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
                descripcion
                descripcionEn
                descripcionDe
                imagen {
                  mediaItemUrl
                }
              }
            }
            spa {
              links {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
              experienciasWellness {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
              destacados {
                descripcionEn
                descripcionEs
                descripcionDe
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
            }
            experienciasUnicas {
              experiencias {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
              destacadas {
                descripcionEn
                descripcionEs
                descripcionDe
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
              }
            }
            sostenibilidad {
              item {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
                externallink {
                  target
                  title
                  url
                }
              }
            }
            contacta {
              item {
                link {
                  ${PAGE_WITH_TRANSLATION}
                }
                externallink {
                  target
                  title
                  url
                }
              }
            }
            menuSuperior {
              tour {
                textoen
                textoes
                textode
                link
                linken
                linkde
              }
              noticias {
                texten
                textes
                textde
                link {
                  url
                }
              }
              reserve {
                textoen
                textoes
                textode
                link
              }
              contactar {
                textoen
                textoes
                textode
                link {
                  url
                }              
              }
            }
            menuPrincipal {
              type
              link {
                ${PAGE_WITH_TRANSLATION}
              }
            }
          }
        }
      
    }
    
    
    
    
    
     
    `,
    {
      variables: {
        slug,
      },
    }
  );
  return data;
}
