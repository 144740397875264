import fetchApi from "ab-fetch-api";

export async function getDatosPersonales(id, token) {
  const data = await fetchApi(
    `
    query getDatosPersonales($id: ID!) {
        customer(id: $id) {
          id
          databaseId
          email
          firstName
          lastName
          role
          billing {
            phone
            address1
            address2
            city
            country
            postcode
            state
          }
          shipping {
            phone
            address1
            address2
            city
            country
            postcode
            state
          }
        }
      }    
  `,
    {
      variables: { id },
    },
    token
  );
  return data;
}
