
const getGuestString = (guests, rooms) => {
  let guestsString = `${guests},0,0,0`;
  if (rooms == 2) {
    if (guests == 3) {
      guestsString = `2,1`;
    }
    if (guests == 4) {
      guestsString = `2,2`;
    }
    if (guests == 5) {
      guestsString = `2,2,1`;
    }
    if (guests == 6) {
      guestsString = `2,2,2`;
    }
  } else if (rooms == 3) {
    if (guests == 3) {
      guestsString = `1,1,1`;
    }
    if (guests == 4) {
      guestsString = `2,1,1`;
    }
    if (guests == 5) {
      guestsString = `2,2,1`;
    }
    if (guests == 6) {
      guestsString = `2,2,2`;
    }
    if (guests == 7) {
      guestsString = `3,2,2`;
    }
    if (guests == 8) {
      guestsString = `3,3,2`;
    }
  } else if (rooms == 4) {

    if (guests == 4) {
      guestsString = `1,1,1,1`;
    }
    if (guests == 5) {
      guestsString = `2,1,1,1`;
    }
    if (guests == 6) {
      guestsString = `2,2,1,1`;
    }
    if (guests == 7) {
      guestsString = `2,2,2,1`;
    }
    if (guests == 8) {
      guestsString = `2,2,2,2`;
    }
    if (guests == 9) {
      guestsString = `3,2,2,2`;
    }
    if (guests == 10) {
      guestsString = `4,2,2,2`;
    }
  }

  return guestsString;
};

export default getGuestString;