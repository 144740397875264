import { defaultState } from "./provider";

export const encodeToken = (token) => {
  if (!token) {
    return null;
  }

  return btoa(token);
};

export default function reducer(state, action) {
  // console.log("EboxReducer", state, action);

  switch (action.type) {
    case "reset_ebox":
      // localStorage.removeItem(process.env.CART_SESSION_NAME);
      return {
        ...defaultState,
      };
    case "remove_item":
    case "set_dedicatoria":
    case "add_item_to_ebox":
    case "add_items_to_ebox":
      return {
        ...state,
        ...action.payload,
      };



    default:
      console.log("EboxReducer - error - action unknown", action);
  }
}
