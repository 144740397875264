import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { getTranslation } from "./methods/getTranslation";

const SubMenuBodega = ({
  activeSection,
  activeSubSection,
  setActiveSubSection,
  menu,
  type,
  isSticky,
  t,
  i18n,
}) => {
  // console.log("SubMenuBodega", menu?.vinos);

  return (
    <div
      className={`submenu bodega ${
        activeSection == type || activeSubSection == type ? " open" : ""
      }${isSticky ? " sticky" : ""}`}
      onMouseEnter={() => setActiveSubSection(type)}
      onMouseLeave={() => setActiveSubSection(null)}
    >
      <div>
        <div className="left">
          <span>{t("hilo-conductor")}</span>
          <div>
            {menu?.vinos.map((itm, index) => (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`sub-menu-bodega-wine-${index}`}
              >
                <a onClick={() => setActiveSubSection(null)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            ))}
            <Link prefetch={false} href={t("/tienda")}>
              <a onClick={() => setActiveSubSection(null)}>{t("ver-todos")}</a>
            </Link>
          </div>
        </div>
        <div className="left">
          <span>{t("comprar-seleccion")}</span>
          <div>
            {menu?.categorias.map((itm, index) => (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`sub-menu-bodega-${index}`}
              >
                <a onClick={() => setActiveSubSection(null)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            ))}
          </div>
        </div>
        <div className="right">
          {menu?.productosDestacados
            .filter((producto) => producto?.producto)
            ?.map((itm, index) => (
              <div key={`sub-menu-bodega-productos-${index}`}>
                <Link
                  prefetch={false}
                  href={`${t("/tienda")}/${itm?.producto?.slug}`}
                >
                  <a onClick={() => setActiveSubSection(null)}>
                    <div className="image">
                      <Image
                        layout="fill"
                        src={itm?.producto?.featuredImage?.node?.mediaItemUrl}
                        alt={
                          i18n.language == "es"
                            ? itm?.producto?.name
                            : i18n.language == "de"
                            ? itm?.producto?.productos_aleman
                                ?.subtituloAleman || itm?.producto?.name
                            : itm?.producto?.productos_ingles
                                ?.subtituloIngles || itm?.producto?.name
                        }
                      />
                    </div>

                    <span>
                      {i18n.language == "es"
                        ? itm?.producto?.name
                        : i18n.language == "de"
                        ? itm?.producto?.productos_aleman?.subtituloAleman ||
                          itm?.producto?.name
                        : itm?.producto?.productos_ingles?.subtituloIngles ||
                          itm?.producto?.name}
                    </span>
                  </a>
                </Link>
                <Link prefetch={false} href={t("/tienda")}>
                  <a onClick={() => setActiveSubSection(null)}>
                    {t("ver-todos")}
                  </a>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SubMenuBodega;
